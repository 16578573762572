import { RootState } from 'redux/store';
import { connect } from 'react-redux';
import { Link, useLocation, matchPath } from 'react-router-dom';
import { PATHS } from 'services/routes';
import { useWindowSize } from 'usehooks-ts';

import { getCurrentSite } from 'concepts/site';
import {
  getSubscription,
  getSubscriptionInfo,
  Subscription,
  SubscriptionInfo,
  isTrial,
  isActive,
  isCancelled,
} from 'concepts/subscription';
import { getAvailablePlans, AvailablePlans, Plan } from 'concepts/plan';
import { pathToSettingsSubscriptionOverview, pathToSettingsSubscriptionPlanSelect } from 'services/routes';
import styles from './FooterNotice.module.scss';
import WebComponent from 'utils/web-component';
import { cioTrackEvent } from 'utils/customer-io';

const getIsSubscriptionPath = (currentPath: string) => {
  return matchPath(currentPath, { path: PATHS.SETTINGS_SUBSCRIPTION_OVERVIEW });
};

type TrialNoticeProps = {
  daysLeft: number;
  site: Site;
};

const TrialNotice = ({ daysLeft, site }: TrialNoticeProps) => (
  <aside className={styles.footerNotice}>
    {daysLeft === 0 ? 'Your trial expires today' : `${daysLeft} day${daysLeft > 1 ? 's' : ''} left in your trial`}

    <WebComponent 
      tag="fl-button" 
      variant="tetriary" 
      size="small" 
      to={pathToSettingsSubscriptionPlanSelect(site.site_url)} 
      class="ml"
      onClick={() => cioTrackEvent("Select a plan → link click")}
    >
      Select a plan →
    </WebComponent>
  </aside>
);

type YearlyNoticeProps = {
  availablePlans: AvailablePlans;
  site: Site;
  subscription: Subscription;
};

const YearlyNotice = ({ availablePlans, site, subscription }: YearlyNoticeProps) => {
  if (!site || !site || !availablePlans || !subscription?.valid_until) {
    return null;
  }

  const currentPlan = subscription?.plan || '';
  const nextPlan = subscription?.next_plan || '';
  const matchingYearlyPlan = availablePlans.yearly.find((plan: Plan) =>
    nextPlan ? plan.id.replace('_yearly', '') === nextPlan : plan.id.replace('_yearly', '') === currentPlan
  );
  let currencySymbol;

  switch (site.currency) {
    case 'USD':
      currencySymbol = '$';
      break;
    case 'EUR':
    case '':
    case null:
      currencySymbol = '€';
      break;
    default:
      currencySymbol = site.currency;
  }

  const savings = [
    currencySymbol === '$' ? currencySymbol : '',
    matchingYearlyPlan?.savings,
    currencySymbol !== '$' ? currencySymbol : '',
  ].join('');

  return (
    <aside className={styles.footerNotice}>
      Save {savings} / year by moving to yearly subscription
      <Link to={pathToSettingsSubscriptionOverview(site.site_url)} className={styles.footerNoticeCTA}>
        Switch to a yearly plan →
      </Link>
    </aside>
  );
};

type CancelledNoticeProps = {
  daysLeft: number;
  site: Site;
};

const CancelledNotice = ({ daysLeft, site }: CancelledNoticeProps) => (
  <aside className={`${styles.footerNotice} ${styles.footerNoticeCancelled}`}>
    You have unsubscribed.{' '}
    {daysLeft === 0
      ? 'Your subscription expires today'
      : `There ${daysLeft > 1 ? 'are' : 'is'} ${daysLeft} day${daysLeft > 1 ? 's' : ''} left in your subscription`}
    <Link to={pathToSettingsSubscriptionPlanSelect(site.site_url)} className={styles.footerNoticeCTA}>
      Subscribe to a plan →
    </Link>
  </aside>
);

type FooterNoticeProps = {
  availablePlans: AvailablePlans;
  site: Site;
  subscription: Subscription;
  subscriptionInfo: SubscriptionInfo;
};

const FooterNotice = ({ availablePlans, site, subscription, subscriptionInfo }: FooterNoticeProps) => {
  const { pathname } = useLocation();
  const { width: windowWidth } = useWindowSize();

  if (!subscription || !subscription.valid_until) {
    return null;
  }

  const { daysLeft } = subscriptionInfo;

  const isCurrentOrNextPlanYearly =
    (subscription.plan || '').indexOf('_yearly') !== -1 || (subscription.next_plan || '').indexOf('_yearly') !== -1;

  if (windowWidth < 768) {
    return (
      <aside className={styles.footerNotice}>
        <div className={styles.footerNoticeMobile}>Please login with your desktop device for the full experience</div>
      </aside>
    );
  }

  const isSubscriptionPath = getIsSubscriptionPath(pathname);

  if (isActive(subscription) && !isCurrentOrNextPlanYearly && site && !isSubscriptionPath) {
    return <YearlyNotice availablePlans={availablePlans} site={site} subscription={subscription} />;
  }

  if (isTrial(subscription) && daysLeft !== null && daysLeft >= 0 && site && !isSubscriptionPath) {
    return <TrialNotice daysLeft={daysLeft} site={site} />;
  }

  if (isCancelled(subscription) && daysLeft && site && !isSubscriptionPath) {
    return <CancelledNotice daysLeft={daysLeft} site={site} />;
  }

  return null;
};

const mapStateToProps = (state: RootState) => ({
  availablePlans: getAvailablePlans(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  subscriptionInfo: getSubscriptionInfo(state),
});

export { FooterNotice };
export default connect(mapStateToProps)(FooterNotice);
