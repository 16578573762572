import { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';

import Heading from 'components/Heading';
import { getCurrentSiteId, getSitesLoadingStatus, getSiteSettings } from 'concepts/site';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import Page from 'components/Page';
import styles from './EmbedScreenEditView.module.scss';
import ScreenPreview from '../components/ScreenPreview';
import {
  getEmbedScreensLoadingState,
  getEmbedScreenUpdateError,
  getEmbedScreenUpdatingState,
} from 'concepts/embed-screen';
import { fetchEmbedScreens, getEditEmbedScreen, saveEditEmbedScreen } from 'concepts/embed-screen/edit';
import { getStyleName } from 'concepts/display-list';
import EmbedScreenEditForm from '../components/EmbedScreenEditForm';
import { EmbedScreenEditFormValues } from '../components/EmbedScreenEditForm/types';
import { fetchEmbedThemes, getEmbedThemes } from 'concepts/embed-theme';

type EmbedScreenEditViewProps = ConnectedProps<typeof connector>;

const EmbedScreenEditView = ({
  currentSiteId,
  fetchEmbedScreens,
  fetchEmbedThemes,
  isLoadingSite,
  isLoadingEmbedScreen,
  siteSettings,
  isSaving,
  embedScreen,
  embedThemes,
  embedScreenUpdateError,
  saveEditEmbedScreen,
}: EmbedScreenEditViewProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchEmbedScreens();
      fetchEmbedThemes();
    }
  }, [currentSiteId, fetchEmbedScreens, fetchEmbedThemes]);

  const [editVersion, setEditVersion] = useState(0);
  const pageTitle = embedScreen?.embed ? `Edit ${getStyleName(embedScreen.embed)}` : '';
  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingEmbedScreen;

  const initialValues = useMemo<EmbedScreenEditFormValues | null>(() => {
    if (!embedScreen) {
      return null;
    }

    const { embed } = embedScreen;

    return {
      name: embedScreen.name || '',
      scss: embedScreen.scss || '',

      configuration: embedScreen.configuration,

      embed: {
        name: embed.name,
        embed_theme_id: embed.embed_theme_id ?? null,

        // Rest is configuration
        autoplay: embed.configuration?.autoplay,
        count: embed.configuration?.count,
        extra: embed.configuration?.extra || {},
        infinite_scroll: embed.configuration?.infinite_scroll,
        load_more_text: embed.configuration?.load_more_text,
        locale: embed.configuration?.locale,
        only: embed.configuration?.only,
        post_shown_for_seconds: embed.configuration?.post_shown_for_seconds,
        product_category_ids: embed.configuration?.product_category_ids,
        product_feed_ids: embed.configuration?.product_feed_ids,
        product_ids: embed.configuration?.product_ids,
        product_show_all_fallback: embed.configuration?.product_show_all_fallback,
        refresh_type: embed.configuration?.refresh_type,
        refresh: embed.configuration?.refresh,
        section_ids: embed.configuration?.section_ids,
        show_comments: embed.configuration?.show_comments,
        show_likes: embed.configuration?.show_likes,
        show_more_text: embed.configuration?.show_more_text,
        show_profile: embed.configuration?.show_profile,
        show_retweets: embed.configuration?.show_retweets,
        style: embed.configuration?.style,
        tags: embed.configuration?.tags,

        theme_css_variables: {
          '--fl-background': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
            '--fl-background'
          ],
          '--fl-item-background': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
            '--fl-item-background'
          ],
          '--fl-text-color': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
            '--fl-text-color'
          ],
        },
      },
    };
  }, [embedScreen, embedThemes]);

  return (
    <Page className={styles.editorPage}>
      <Helmet>
        <title>
          Flockler {'\u203A'} {pageTitle}
        </title>
      </Helmet>

      {!!pageTitle && (
        <Heading level="h1" type="primary">
          {pageTitle}
        </Heading>
      )}

      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}

      {!isLoadingView && !embedScreen && (
        <>
          <Heading level="h1" type="primary">
            Screen not found
          </Heading>
          <section style={{ maxWidth: '20rem', margin: '0 auto', textAlign: 'center' }}>
            <img
              src="https://media.giphy.com/media/QysaA9IHImIC5pUzCj/giphy.gif"
              style={{ maxWidth: '100%', borderRadius: '1rem' }}
              alt="GIF of Seth Meyers acting confused"
            />
          </section>
        </>
      )}

      {!isLoadingView && !!embedScreen && !!initialValues && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, helpers) => {
            saveEditEmbedScreen(values).finally(() => {
              helpers.setSubmitting(false);
              setEditVersion(editVersion + 1);
            });
          }}
        >
          {(formikProps) => (
            <>
              <Heading level="h2" type="tertiary">
                <span className="inline-block w-full text-center">
                  Open the link below on a device connected to a digital screen
                </span>
              </Heading>

              <div className={styles.slideshowUrl}>
                <a
                  className={styles.slideshowUrlLink}
                  href={embedScreen.shortcut_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {embedScreen.shortcut_url} <Icon className={styles.slideshowUrlLinkIcon} type="external-link" />
                </a>
              </div>

              <ScreenPreview
                editVersion={editVersion}
                editValues={formikProps.values}
                hasUnsavedChanges={formikProps.dirty}
                isSaving={isSaving}
                onSave={formikProps.handleSubmit}
                display={embedScreen}
                displayUpdateError={embedScreenUpdateError}
                isScreen
              />

              <EmbedScreenEditForm formik={formikProps} />
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingEmbedScreen: getEmbedScreensLoadingState(state),
  siteSettings: getSiteSettings(state),
  isSaving: getEmbedScreenUpdatingState(state),
  embedScreen: getEditEmbedScreen(state),
  embedThemes: getEmbedThemes(state),
  embedScreenUpdateError: getEmbedScreenUpdateError(state),
});

const mapDispatchToProps = { fetchEmbedScreens, saveEditEmbedScreen, fetchEmbedThemes };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmbedScreenEditView);
