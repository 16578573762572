import useOnboarding from 'hooks/useOnboarding';
import { useEffect } from 'react';

const Onboarding = () => {
  const startOnboarding = useOnboarding();

  useEffect(() => {
    startOnboarding();
  }, [startOnboarding]);

  return null;
};

export default Onboarding;
