import { useField, useFormikContext } from 'formik';
import { Field, Input } from '../../EmbedForm';
import Label from 'components/Label';
import styles from './EmbedUrlAliasField.module.scss';

const sanitizeUrlAlias = (input: string) => {
  return input.toLowerCase().replace(/[^a-z0-9-]+/g, (_match, index, str) => {
    // Replace non-standard characters with "-" only if the previous character is not already "-"
    return str[index - 1] === '-' ? '' : '-';
  });
};

const EmbedUrlAliasField = () => {
  const { values } = useFormikContext<{ name: string }>();
  const [{ name, value }, , { setValue }] = useField<string>('url_alias');

  const handleChange = (name: string) => {
    setValue(sanitizeUrlAlias(name));
  };

  return (
    <Field>
      <div className={styles.labelContainer}>
        <Label htmlFor={name} className="!inline-block !w-auto !align-middle">
          Url Alias
        </Label>
        <button
          type="button"
          onClick={() => handleChange(values.name)}
          className="ml-2 mt-[3px] inline-block align-middle text-xs font-normal text-brand hover:underline"
        >
          Generate from Name
        </button>
      </div>
      <Input id={name} name={name} type="text" onChange={({ target }) => handleChange(target.value)} value={value} />
    </Field>
  );
};

export default EmbedUrlAliasField;
