import { useEffect } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { RootState } from 'redux/store';
import classnames from 'classnames';
import { getSubscription, isTrialSubscribed, Subscription, SubscriptionStateEnum } from 'concepts/subscription';
import { fetchInvoices, getInvoices, getIsLoadingInvoices, Invoice } from 'concepts/invoice';
import { format } from 'date-fns';
import formatPrice from 'services/format-price';

import WebComponent from 'utils/web-component';
import Heading from 'components/Heading';

import PageLoader from 'components/Loader/PageLoader';
import EmptyState from '../EmptyState';
import styles from './InvoiceList.module.scss';
import { getCurrentSite } from 'concepts/site';
import { getStripeSubscription, StripeSubscription } from 'concepts/stripe-subscription';

const PAID = 'paid';
const VOID = 'void';
const DUE = 'due';
const PENDING = 'pending';

type InvoiceStatus = typeof PAID | typeof VOID | typeof DUE | typeof PENDING;

const getInvoiceStatus = (invoice: Invoice): InvoiceStatus => {
  if (invoice.paid) {
    return PAID;
  }

  if (invoice.status === 'void') {
    return VOID;
  }

  return DUE;
};

const pendingSEPADebit =
  ({ subscription, stripeSubscription }: { subscription?: Subscription; stripeSubscription?: StripeSubscription }) =>
  (status: InvoiceStatus): InvoiceStatus => {
    if (
      status === 'due' &&
      stripeSubscription?.payment_method === 'sepa_debit' &&
      (subscription?.state === SubscriptionStateEnum.pending_payment ||
        subscription?.state === SubscriptionStateEnum.trial_subscribed)
    ) {
      return PENDING;
    }

    return status;
  };

type InvoiceListProps = ConnectedProps<typeof connector> & {
  confirmStripePayment?: (clientSecret: string) => void;
};

const InvoiceList = ({
  fetchInvoices,
  site,
  confirmStripePayment,
  invoices,
  isLoadingInvoices,
  subscription,
  stripeSubscription,
}: InvoiceListProps) => {
  useEffect(() => {
    fetchInvoices(site.id);
  }, [fetchInvoices, site]);

  const invoiceListItems = (invoices || []).filter(
    (invoice: Invoice) =>
      invoice.total > 0 ||
      (invoice.line_item_descriptions || []).some((line: string) => line.includes('Referral program reward'))
  );

  if (isLoadingInvoices) {
    return <PageLoader className={styles.loader} />;
  }

  if (isTrialSubscribed(subscription) && !invoiceListItems.length) {
    return (
      <>
        <Heading level="h2" type="secondary" id="invoices">
          Invoices and receipts
        </Heading>
        <p>
          Your free trial expires on {format(new Date(subscription.valid_until as string), 'MMM dd, yyyy')}, and your{' '}
          {stripeSubscription?.payment_method === 'sepa_debit' ? 'account' : 'credit card'} hasn’t been charged yet.
          Once the free trial period is over, you'll receive an email confirmation of the first payment, and the link to
          download your invoice and receipt will show up here.
        </p>
      </>
    );
  }

  if (!invoiceListItems.length) {
    return null;
  }

  const pendingSEPADebitStatus = pendingSEPADebit({ subscription, stripeSubscription });

  return (
    <>
      <Heading level="h2" type="secondary" id="invoices">
        Invoices
      </Heading>

      <table className={styles.invoiceList}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Status</th>
            <th>&nbsp;</th>
          </tr>
        </thead>

        <tbody>
          {!invoices && isLoadingInvoices && (
            <tr className={styles.disabled}>
              <td colSpan={5}>
                <PageLoader className={styles.loader} />
              </td>
            </tr>
          )}

          {!invoiceListItems.length && !isLoadingInvoices && (
            <tr className={styles.disabled}>
              <td colSpan={5}>
                <EmptyState>You don’t have any invoices yet.</EmptyState>
              </td>
            </tr>
          )}
          {!!invoiceListItems.length &&
            invoiceListItems.map((invoice) => {
              const status = pendingSEPADebitStatus(getInvoiceStatus(invoice));

              return (
                <tr
                  className={classnames(styles.invoiceRow, {
                    [styles.voidInvoiceRow]: status === VOID,
                    [styles.dueInvoiceRow]: status === DUE,
                  })}
                  key={invoice.id}
                >
                  <td className={styles.dateColumn}>{format(new Date(invoice.date), 'MMM dd, yyyy')}</td>

                  <td className={styles.descriptionColumn}>
                    {(invoice.line_item_descriptions || []).map((description: string) => (
                      <div key={description}>{description}</div>
                    ))}
                  </td>

                  <td className={styles.totalColumn}>
                    {formatPrice((invoice.total < 0 ? invoice.total : invoice.amount_due) / 100, invoice.currency)}
                  </td>

                  <td
                    className={classnames(styles.statusColumn, {
                      [styles.voidStatusColumn]: status === VOID,
                      [styles.dueStatusColumn]: status === DUE,
                    })}
                  >
                    {status === PAID && 'Paid'}
                    {status === VOID && 'Void'}
                    {status === DUE && 'Due'}
                    {status === PENDING && 'Pending'}
                  </td>

                  <td className={styles.actionColumn}>
                    {invoice.paid && (
                      <a target="_blank" rel="noopener noreferrer" href={invoice.receipt}>
                        Receipt
                      </a>
                    )}

                    {!invoice.paid && invoice.client_secret && confirmStripePayment && (
                      <WebComponent tag="fl-button" size="small" variant="success" onClick={() => confirmStripePayment(invoice.client_secret)}>
                        Approve…
                      </WebComponent>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  invoices: getInvoices(state),
  isLoadingInvoices: getIsLoadingInvoices(state),
  site: getCurrentSite(state),
  subscription: getSubscription(state),
  stripeSubscription: getStripeSubscription(state),
});

const mapDispatchToProps = {
  fetchInvoices,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(InvoiceList);
