import { useState } from 'react';
import sortBy from 'lodash/sortBy';
import * as api from 'services/api';
import { Helmet } from 'react-helmet';

import WebComponent from 'utils/web-component';
import Heading from 'components/Heading';
import Page from 'components/Page';
import { SectionPage } from 'hooks/api/useSections';
import { reviewContentText } from "../../hooks/useReviewForms";

const ReviewFormSectionSelect = ({
  sections,
  category,
  site,
  onSelect,
  mutateSections,
}: {
  sections: Section[];
  site: Site;
  category: ReviewFormCategory;
  onSelect: (sectionUuid: string) => void;
  mutateSections: (func: any) => void;
}) => {
  const [selected, setSelected] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState<string>('');
  const contentText = reviewContentText(category);

  return (
    <Page style={{ maxWidth: '56rem' }}>
      <Helmet>
        <title>Flockler {'\u203A'} Custom Forms</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Which section would you like to store the {contentText}?
      </Heading>

      <div className="mx-auto max-w-lg rounded bg-slate-50 p-6">
        <div className="flex items-end justify-between">
          <div className="mr-2 flex-1">
            <label className="-mt-1">Choose section</label>
            <select
              autoFocus
              defaultValue={selected || ''}
              onChange={(e) => setSelected(e.target.value)}
              className="input"
            >
              <option value="" disabled>
                Select a section…
              </option>

              {sortBy(sections, [(section) => section?.name.toLowerCase()]).map((section) => (
                <option key={section.uuid} value={section.uuid}>
                  {section.name}
                </option>
              ))}
            </select>
          </div>

          <WebComponent tag="fl-button"
            disabled={!selected}
            onClick={selected ? () => {onSelect(selected)} : undefined}
            noclickhandling={true}
            >
            Continue
          </WebComponent>
        </div>

        <form
          className="flex items-end justify-between"
          onSubmit={async (e) => {
            e.preventDefault();

            if (inputValue) {
              await mutateSections(async (pages: SectionPage[]) => {
                const { data: section } = await api.createSection(site.id, { name: inputValue });

                onSelect(section.uuid);

                if (pages) {
                  const [page, ...rest] = pages;
                  return [{ ...page, sections: [section, ...page.sections] }, ...rest];
                }
              });
            }
          }}
        >
          <div className={`flex-1 transition-all ${!!inputValue ? 'mr-2' : ''}`}>
            <label className="mt-4" htmlFor="sectionCreateInput">
              Or create a new section
            </label>
            <input
              required
              className="input"
              id="sectionCreateInput"
              type="text"
              placeholder="Section name…"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>

          {!!inputValue && (
            <WebComponent tag="fl-button" data-button-variant="success" type="submit" css="margin-bottom: 0;">
              Create
            </WebComponent>
          )}
        </form>
      </div>
    </Page>
  );
};

export default ReviewFormSectionSelect;
