// Extend the Window interface to include _cio
declare global {
  interface Window {
    _cio: {
      identify: (data: { id: string }) => void;
      track: (event: string, data: CustomerIoValues) => void;
    };
  }
}

interface CustomerIoValues {
  [key: string]: any;
}

const cioIdentify = (user: User, siteUuid: string) => {
  window._cio.identify({
    id: `${siteUuid}-${user.id}`,
  });
};

const cioTrackEvent = (event: string, data?: CustomerIoValues) => {
  window._cio.track(event, data || {});
};

export { cioIdentify, cioTrackEvent };
