import config from 'config';

type PreviewUrlProps = {
  siteUuid: string;
  siteUrl: string;
  embedUuid: string;
  embedUrlAlias: string;
  urlAliasEnabled: boolean;
  style?: string;
};

const getEmbedPreviewUrl = ({ siteUuid, siteUrl, embedUuid, embedUrlAlias, urlAliasEnabled, style }: PreviewUrlProps) => {
  const slug = urlAliasEnabled ? `${siteUrl}/${embedUrlAlias}` : `${siteUuid}/${embedUuid}`;
  return `${config.flocklerPluginsUrl}/embed/preview/${slug}`;
};

export default getEmbedPreviewUrl;
