import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { connect, ConnectedProps } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';

import AddToWordPressModal from '../components/AddToWordPressModal';
import Heading from 'components/Heading';
import { getCurrentSiteId, getSiteSettings, getSitesLoadingStatus } from 'concepts/site';
import { getEmbedsLoadingState } from 'concepts/embed';
import { getEditEmbedAsDisplay, fetchEmbeds } from 'concepts/embed/edit';
import { fetchEmbedThemes } from 'concepts/embed-theme';
import { getUserFirstName } from 'concepts/user';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import Page from 'components/Page';
import HelpScoutLink from 'components/HelpScoutLink';
import SmallDisplayPreviewBrowser from '../components/SmallDisplayPreviewBrowser';
import EmbedCodeCopier from '../components/EmbedCodeCopier';
import CardLink from 'components/CardLink';
import getEmbedCode from 'services/embed-code';
import { formatEmbedCodeMailtoLink } from 'services/display-mailto-link';
import { pathToEmbed, pathToDisplay, pathToNewDisplay, pathToAutomatedFeeds } from 'services/routes';
import useSiteUrl from 'hooks/useSiteUrl';
import useSiteUuid from 'hooks/useSiteUuid';
import styles from './NewEmbedView.module.scss';

type NewEmbedViewProps = ConnectedProps<typeof connector>;

const NewEmbedView = ({
  siteId,
  embed,
  fetchEmbeds,
  fetchEmbedThemes,
  isLoadingEmbed,
  isLoadingSite,
  userFirstName,
  siteSettings,
}: NewEmbedViewProps) => {
  useEffect(() => {
    if (siteId) {
      fetchEmbeds();
      fetchEmbedThemes();
    }
  }, [siteId, fetchEmbeds, fetchEmbedThemes]);
  const [showAddToWordPressModal, setShowAddToWordPressModal] = useState(false);
  const isLoadingView = !siteId || isLoadingSite || isLoadingEmbed;
  const siteUrl = useSiteUrl();
  const siteUuid = useSiteUuid();

  return (
    <Page className={styles.page}>
      <Helmet>
        <title>Flockler {'\u203A'} New embed</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Great choice{userFirstName ? `, ${userFirstName}` : ''}! Next, add the Embed Code to your website
      </Heading>

      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}

      {!isLoadingView && !embed && <p>Error loading Embed...</p>}

      {!isLoadingView && !!embed && (
        <>
          <div className={styles.cols}>
            <div className={styles.leftCol}>
              <Heading level="h2" type="tertiary">
                Embed code for the {embed.styleName}
              </Heading>

              <EmbedCodeCopier
                className={styles.embedCodeCopier}
                embedCode={getEmbedCode(embed, { urlAliasEnabled: !!siteSettings?.embed_url_alias_enabled })}
                size="large"
                siteUuid={siteUuid}
                embedStyle={embed.styleName}
                embedUuid={embed.uuid}
                embedConfig={embed.configuration ?? {}}
                embedUrlAliasEnabled={siteSettings?.embed_url_alias_enabled}
                embedUrlAlias={embed.url_alias}
                siteUrl={siteUrl}
              />

              <div className={styles.actions}>
                <Link
                  to={pathToEmbed(siteUrl, embed.uuid)}
                  className={classnames(styles.actionButton, styles.primaryActionButton)}
                >
                  <Icon type="customize" className={styles.actionIcon} />
                  Edit style and settings
                </Link>
                <a className={styles.actionButton} target="_blank" rel="noopener noreferrer" href={embed.previewUrl}>
                  <Icon type="eye-circle" className={styles.actionIcon} />
                  Preview
                </a>
                <a
                  className={styles.actionButton}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={formatEmbedCodeMailtoLink(embed.type, embed.embedCode)}
                >
                  <Icon type="email-circle" className={styles.actionIcon} />
                  Send via email
                </a>

                <button className={styles.actionButton} onClick={() => setShowAddToWordPressModal(true)}>
                  <Icon type="wordpress-circle" className={styles.actionIcon} />
                  Add to WordPress
                </button>
              </div>
            </div>
            <div className={styles.rightCol}>
              <Heading level="h2" type="tertiary">
                Preview of your embed
              </Heading>

              <SmallDisplayPreviewBrowser
                url={`${embed.previewUrl}?_editVersion=0`}
                className={styles.preview}
                displayType={embed.type}
              />
            </div>

            <div className={styles.leftCol}>
              <div className={styles.instructions}>
                <p>
                  A great thing about Flockler is that{' '}
                  <span className={styles.highlight}>
                    you can create multiple layouts, customize the look, and display different content on separate pages
                    and digital services.
                  </span>
                </p>

                <p>
                  We are always happy to help with any questions you might have via our{' '}
                  <HelpScoutLink>live chat</HelpScoutLink>.
                </p>

                <p>
                  <Link className={styles.navlink} to={pathToDisplay(siteUrl)}>
                    View all layouts
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <section className="mt-10">
            <Heading level="h2" type="primary">
              Try these next
            </Heading>
            <div className="flex flex-col md:flex-row">
              <CardLink
                to={pathToNewDisplay(siteUrl)}
                iconType="code-circle"
                text="Create another display layout"
                description="Display layouts can be customized to display specific subset of content."
              />

              <CardLink
                to={pathToAutomatedFeeds(siteUrl)}
                iconType="robot-circle"
                text="Create a new Automated Feed"
                description="Automated Feeds collect the content for you to display."
              />
            </div>
          </section>
          {showAddToWordPressModal && embed.uuid && siteUuid && (
            <AddToWordPressModal
              siteUuid={siteUuid}
              embedUuid={embed.uuid}
              dismissAction={() => {
                setShowAddToWordPressModal(false);
              }}
            />
          )}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingEmbed: getEmbedsLoadingState(state),
  embed: getEditEmbedAsDisplay(state),
  userFirstName: getUserFirstName(state),
  siteSettings: getSiteSettings(state),
});

const mapDispatchToProps = { fetchEmbeds, fetchEmbedThemes };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewEmbedView);
