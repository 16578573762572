import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { pathToReviewsEditForm, pathToReviewsExportContacts } from 'services/routes';
import ReviewFormsListItemMeta from '../ReviewFormsListItemMeta';
import ReviewFormListItemPreview from '../ReviewFormListItemPreview';
import ReviewFormsListItemEmbedCodeCopier from '../ReviewFormsListItemEmbedCodeCopier';

const ReviewFormsListItem = ({
  index,
  form,
  site,
  sections,
  reviewsServiceDetails,
  reviewFormIsAboutToBeDeleted,
  setReviewFormAboutToBeDeleted,
  scheduleAssetsDownload,
}: {
  index: number;
  form: ReviewForm;
  site: Site;
  sections: Section[];
  reviewsServiceDetails: ReviewsServiceDetails;
  reviewFormIsAboutToBeDeleted: boolean;
  setReviewFormAboutToBeDeleted: (form: ReviewForm) => void;
  scheduleAssetsDownload: (form: ReviewForm) => void;
}) => {
  return (
    <article
      className={`
      mb-4 flex w-full animate-fade-in rounded-lg border border-dashed p-4 py-2 opacity-0 outline-none hover:z-10 md:py-4
        ${
          reviewFormIsAboutToBeDeleted
            ? 'z-10 border-red-300 border-b-red-300 bg-red-50 bg-opacity-50'
            : 'border-slate-300 bg-white hover:border-slate-400'
        }
      `}
      style={{
        animationDelay: `${Math.min(index * 80, 1000)}ms`,
        animationDuration: '600ms',
      }}
    >
      <div className="hidden w-[12rem] min-w-[12rem] md:block">
        <ReviewFormListItemPreview
          editPath={pathToReviewsEditForm(site.site_url, form.uuid as string)}
          form={form}
          apiBaseUrl={reviewsServiceDetails.apiBaseUrl}
        />
      </div>

      <div className="w-full md:ml-6 md:pt-2">
        <div className="space-y-3">
          <h2 className="text-lg font-bold">
            <Link to={pathToReviewsEditForm(site.site_url, form.uuid as string)} className="text-slate-900">
              {form.name}
            </Link>
          </h2>

          <ReviewFormsListItemMeta site={site} sections={sections} form={form} />

          <ReviewFormsListItemEmbedCodeCopier form={form} />

          <div className="flex flex-col md:flex-row md:space-x-6">
            <Link
              to={pathToReviewsEditForm(site.site_url, form.uuid as string)}
              className="flex items-center py-2 text-sm font-semibold text-brand"
            >
              <Icon type="customize" className="mr-2 h-5 w-5" />
              Edit the form
            </Link>

            <a
              className="flex items-center py-2 text-sm font-semibold text-brand"
              target="_blank"
              rel="noopener noreferrer"
              href={`${reviewsServiceDetails.apiBaseUrl}/${form.siteUuid}/forms/${form.uuid}/preview`}
            >
              <Icon type="eye-circle" className="mr-2 h-5 w-5" />
              Preview
            </a>

            <Link
              className="flex items-center py-2 text-sm font-semibold text-brand"
              to={pathToReviewsExportContacts(site.site_url, { form: form.uuid })}
            >
              <Icon type="form-circle" className="mr-2 h-5 w-5" />
              Export contacts
            </Link>

            <a
              className="flex items-center py-2 text-sm font-semibold text-brand cursor-pointer"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => scheduleAssetsDownload(form)}
            >
              <Icon type="email-circle" className="mr-2 h-5 w-5" />
              Export assets
            </a>

            <button
              type="button"
              onClick={() => setReviewFormAboutToBeDeleted(form)}
              className="nline-flex absolute right-1 top-1 h-10 flex-shrink-0 items-center rounded border border-transparent px-2 text-sm font-semibold text-slate-300 outline-none hover:border-red-700 hover:text-red-700 focus-visible:border-red-700 focus-visible:text-red-700 md:right-2 md:top-2"
              title="Delete review form"
            >
              <Icon type="remove-circle" aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
        </div>
      </div>
    </article>
  );
};

ReviewFormsListItem.displayName = 'ReviewFormsListItem';
export default ReviewFormsListItem;
