const USER_ROLE_WEIGHTS = {
  admin: 100,
  owner: 3,
  manager: 2,
  editor: 1,
};

/**
 * Get sorted list of user roles based on the user role level
 * @param {UserRole[]} userRoles - A list of user roles
 * @returns {UserRole[]} - User roles sorted from owner to editor
 */
export const sortedUserRoles = (userRoles: UserRole[]): UserRole[] => {
  return [...userRoles].sort(function (a: UserRole, b: UserRole) {
    const weightA = USER_ROLE_WEIGHTS[a.role_name as UserRoleType];
    const weightB = USER_ROLE_WEIGHTS[b.role_name as UserRoleType];

    if (weightB < weightA) {
      return -1;
    }
    if (weightB > weightA) {
      return 1;
    }

    return 0;
  });
};

/**
 * Returns all roles the users have as a single array
 * @param {User[]} users - A list of users including their roles
 * @returns {UserRole[]}
 */
export const allRolesForUsers = (users: User[]): UserRole[] => {
  const rolesPerUser = users.filter((user) => user.user_roles).map((user) => user.user_roles);
  return rolesPerUser.reduce((allRoles: UserRole[], usersRoles) => {
    return [...allRoles].concat(...(usersRoles as UserRole[]));
  }, []);
};

/**
 * Returns all role types a given user role can invite to their site
 * @param {UserRoleType} role - A user role level
 * @returns {UserRoleType[]}
 */
export const rolesThatUserCanInvite = (role: UserRoleType): UserRoleType[] => {
  switch (role) {
    case 'admin':
    case 'owner':
      return ['owner', 'manager', 'editor'];
    case 'manager':
      return ['manager', 'editor'];
    default:
      return [];
  }
};

/**
 * Returns all role types a given user role can edit on their site
 * @param {UserRoleType} role - A user role level
 * @returns {UserRoleType[]}
 */
export const rolesThatUserCanEdit = (role: UserRoleType): UserRoleType[] => {
  switch (role) {
    case 'admin':
    case 'owner':
      return ['owner', 'manager', 'editor'];
    case 'manager':
      return ['editor'];
    default:
      return [];
  }
};

/**
 * Returns all role types a given user role can remove from their site
 * @param {UserRoleType} role - A user role level
 * @returns {UserRoleType[]}
 */
export const rolesThatUserCanRemove = (role: UserRoleType): UserRoleType[] => {
  switch (role) {
    case 'admin':
    case 'owner':
      return ['owner', 'manager', 'editor'];
    case 'manager':
      return ['editor'];
    default:
      return [];
  }
};

/**
 * Returns whether a user role level can remove another user role level user
 * @param {UserRoleType} role - A user role level that does the removing
 * @param {UserRoleType} roleToRemove - A user role level that is being removed
 * @returns {boolean}
 */
export const canRoleRemoveRole = (role: UserRoleType, roleToRemove: UserRoleType): boolean => {
  return rolesThatUserCanRemove(role).includes(roleToRemove);
};

/**
 * Returns whether a user role level can edit another user role level user
 * @param {UserRoleType} role - A user role level that does the removing
 * @param {UserRoleType} roleToEdit - A user role level that is being removed
 * @returns {boolean}
 */
export const canRoleEditRole = (role: UserRoleType, roleToEdit: UserRoleType): boolean => {
  return rolesThatUserCanEdit(role).includes(roleToEdit);
};

/**
 * Returns whether a user role level can access a specific path
 * @param {UserRoleType} role - A user role level that the current user has for this site
 * @param {string} path - A path user is trying to access
 * @returns {boolean}
 */
export const canRoleAccessPath = (role: UserRoleType, path: string): boolean => {
  const rolesThatCanAccessPaths = {
    '/account': ['admin', 'owner', 'manager', 'editor'],
    '/analytics': ['admin', 'owner', 'manager'],
    '/layouts': ['admin', 'owner', 'manager'],
    '/feeds': ['admin', 'owner', 'manager'],
    '/custom-forms': ['admin', 'owner', 'manager'],
    '/sections': ['admin', 'owner', 'manager', 'editor'],
    '/settings': ['admin', 'owner', 'manager'],
    '/settings/subscription': ['admin', 'owner'],
    '/rights': ['admin', 'owner', 'manager'],
  } as any;

  return (rolesThatCanAccessPaths[path] || []).includes(role);
};
