import { useEffect, useState } from 'react';
import uniq from 'lodash/uniq';
import difference from 'lodash/difference';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';

import ReviewSidepanelBlock from '../ReviewSidepanelBlock';
import ReviewSidepanelBlockFieldRow from '../ReviewSidepanelBlockFieldRow';
import ReviewSidepanelBlockEditModal from '../ReviewSidepanelBlockEditModal';
import AnimateFadeIn from 'components/AnimateFadeIn';
import Icon from 'components/Icon';
import { DEFAULT_REVIEW_FIELD_IDS, FORM_FIELD_LABELS, REVIEW_CATEGORY_OPTIONS } from 'services/reviews';

const DEFAULT_FIELD_IDS: Readonly<ReviewFormFieldIdentifier[]> = Object.freeze([
  'rating',
  'text',
  'infoText',
  'image',
  'video',
  'firstName',
  'lastName',
  'email',
  'terms',
  'checkbox',
]);

const CUSTOM_FIELDS: Readonly<ReviewFormFieldIdentifier[]> = Object.freeze([
  'customFieldA',
  'customFieldB',
  'customFieldC',
  'customFieldD',
  'customFieldE',
]);

const isCustomField = (fieldId: ReviewFormFieldIdentifier) => CUSTOM_FIELDS.includes(fieldId);

const sortedFieldIds = (fieldIds: ReviewFormFieldIdentifier[], form: ReviewForm) => {
  const nonReviewFields = form.category !== REVIEW_CATEGORY_OPTIONS[1] ? [] : DEFAULT_REVIEW_FIELD_IDS;
  const enabledFieldIds = Object.keys(form.config.fields).sort((a, b) => {
    return form.config.fields[a].orderNumber - form.config.fields[b].orderNumber;
  });
  const disabledFieldIds = fieldIds.filter((fieldId) => !form.config.fields[fieldId]);
  return Object.freeze(difference(uniq([...enabledFieldIds, ...disabledFieldIds]), nonReviewFields)) as ReviewFormFieldIdentifier[];
};

const ReviewSidepanelBlockFields = ({
  form,
  reorderFields,
  setFocusedField,
  updateField,
  toggleField,
  isOpen,
  onToggle,
}: {
  form: ReviewForm;
  reorderFields: (orderedFieldIds: ReviewFormFieldIdentifier[]) => void;
  setFocusedField: React.Dispatch<React.SetStateAction<ReviewFormFieldIdentifier | null>>;
  updateField: (fieldId: ReviewFormFieldIdentifier, fieldDetails: ReviewFormFieldDetails) => void;
  toggleField: (fieldId: ReviewFormFieldIdentifier, orderedFieldIds: ReviewFormFieldIdentifier[]) => void;
  isOpen: boolean;
  onToggle: () => void;
}) => {
  const [fieldIdInEdit, setFieldIdInEdit] = useState<ReviewFormFieldIdentifier | null>(null);
  const [fieldInEdit, setFieldInEdit] = useState<ReviewFormFieldDetails | null>(null);

  const editField = (fieldId: ReviewFormFieldIdentifier | null) => {
    const field = fieldId ? (form.config.fields[fieldId] as ReviewFormFieldDetails) : null;
    setFieldIdInEdit(fieldId);
    setFieldInEdit(field);
  };

  const cancelEdit = () => {
    setFieldIdInEdit(null);
    setFieldInEdit(null);
  };

  const saveEdits = (fieldId: ReviewFormFieldIdentifier, newFieldDetails: ReviewFormFieldDetails) => {
    updateField(fieldId, newFieldDetails);
    setFieldIdInEdit(null);
    setFieldInEdit(null);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // orderedFields determines the order of the fields in the sidepanel
  const [orderedFields, setOrderedFields] = useState<ReviewFormFieldIdentifier[]>(
    sortedFieldIds(
      uniq([
        ...DEFAULT_FIELD_IDS,
        ...(Object.keys(form.config.fields) as ReviewFormFieldIdentifier[]),
      ]) as ReviewFormFieldIdentifier[],
      form
    )
  );

  // Ordered fields should be updated updated when the form config is updated
  useEffect(() => {
    setOrderedFields((o) => sortedFieldIds(o, form));
  }, [form]);

  const handleDragEnd = (e: DragEndEvent) => {
    if (e.over?.id && e.over.id !== e.active.id) {
      // Change fields list order
      const fromIndex = orderedFields.indexOf(e.active.id as ReviewFormFieldIdentifier);
      const toIndex = orderedFields.indexOf(e.over.id as ReviewFormFieldIdentifier);
      const element = orderedFields[fromIndex];
      const newFieldsOrder = [...orderedFields];
      newFieldsOrder.splice(fromIndex, 1);
      newFieldsOrder.splice(toIndex, 0, element);

      setOrderedFields(newFieldsOrder);

      // Change form fields order numbers if moved field is a form field
      if (form.config.fields[e.active.id]) {
        const orderedFieldIds = newFieldsOrder.filter((fieldId) => !!form.config.fields[fieldId]);
        reorderFields(orderedFieldIds);
      }
    }
  };

  const onToggleField = (fieldIdentifier: ReviewFormFieldIdentifier) => {
    const isNextChecked = !form.config.fields[fieldIdentifier];
    const orderedFieldIds = orderedFields.filter((fieldId) => {
      // include if checked, exclude if unchecked
      if (fieldId === fieldIdentifier) return isNextChecked;

      return !!form.config.fields[fieldId];
    });

    toggleField(fieldIdentifier, orderedFieldIds);
  };

  const addCustomField = () => {
    let fieldAdded = false;
    CUSTOM_FIELDS.forEach((fieldId) => {
      if (!fieldAdded && !orderedFields.includes(fieldId)) {
        fieldAdded = true;
        toggleField(fieldId, [...orderedFields, fieldId]);
      }
    });
  };

  const hasAllCustomFieldsAdded = CUSTOM_FIELDS.every((fieldId) => orderedFields.includes(fieldId));

  return (
    <>
      <AnimateFadeIn animationDelay="200ms">
        <ReviewSidepanelBlock title="Fields" isOpen={isOpen} onToggle={onToggle}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext items={orderedFields} strategy={verticalListSortingStrategy}>
              <div>
                {orderedFields.map((fieldId: ReviewFormFieldIdentifier) => {
                  let fieldLabel = FORM_FIELD_LABELS[fieldId];

                  if (isCustomField(fieldId)) {
                    fieldLabel = form.config.fields[fieldId]?.label || fieldLabel;
                  }

                  return (
                    <ReviewSidepanelBlockFieldRow
                      key={fieldId}
                      fieldId={fieldId}
                      field={form.config.fields[fieldId]}
                      fixedLabel={fieldLabel}
                      checked={!!form.config.fields[fieldId]}
                      setFocusedField={(fieldId: ReviewFormFieldIdentifier | null) => {
                        setFocusedField(fieldId);
                      }}
                      setFieldInEdit={() => editField(fieldId)}
                      toggleField={() => onToggleField(fieldId)}
                    />
                  );
                })}
              </div>
            </SortableContext>
          </DndContext>

          {!hasAllCustomFieldsAdded && (
            <div className="border-t border-t-slate-200 pt-3">
              <button
                type="button"
                className="mx-3 flex items-center space-x-2 text-sm font-semibold text-slate-400 hover:text-green-600 focus-visible:text-green-600"
                onClick={addCustomField}
              >
                <Icon type="list-add" />
                <span>Add a custom field…</span>
              </button>
            </div>
          )}
        </ReviewSidepanelBlock>
      </AnimateFadeIn>

      {fieldInEdit && fieldIdInEdit && (
        <ReviewSidepanelBlockEditModal
          fieldId={fieldIdInEdit}
          field={fieldInEdit}
          text={form.config.text}
          cancelAction={cancelEdit}
          confirmAction={saveEdits}
        />
      )}
    </>
  );
};

ReviewSidepanelBlockFields.displayName = 'ReviewSidepanelBlockFields';
export default ReviewSidepanelBlockFields;
