import { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';

import Heading from 'components/Heading';
import { getCurrentSiteId, getSitesLoadingStatus, getSiteSettings } from 'concepts/site';
import { getSlideshowsLoadingState, getSlideshowUpdatingState, getSlideshowUpdateError } from 'concepts/slideshow';
import { getEditSlideshow, saveEditSlideshow, fetchSlideshows } from 'concepts/slideshow/edit';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Icon from 'components/Icon';
import Page from 'components/Page';
import styles from './SlideshowEditView.module.scss';
import SlideshowEditForm from '../components/SlideshowEditForm';
import ScreenPreview from '../components/ScreenPreview';
import useQuery from 'hooks/useQuery';

type SlideshowEditViewProps = ConnectedProps<typeof connector>;

const SlideshowEditView = ({
  currentSiteId,
  fetchSlideshows,
  isLoadingSite,
  isLoadingSlideshow,
  isSaving,
  slideshow,
  slideshowUpdateError,
  saveEditSlideshow,
  siteSettings,
}: SlideshowEditViewProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchSlideshows();
    }
  }, [currentSiteId, fetchSlideshows]);

  const [editVersion, setEditVersion] = useState(0);
  const params = useQuery();

  const isNew = !!params.get('new');
  const pageTitle = `New Slideshow`;
  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingSlideshow;

  return (
    <Page className={styles.editorPage}>
      <Helmet>
        <title>
          Flockler {'\u203A'} {pageTitle}
        </title>
      </Helmet>

      <Heading level="h1" type="primary">
        {pageTitle}
      </Heading>

      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}

      {!isLoadingView && !slideshow && <p>Error loading slideshow...</p>}

      {!isLoadingView && !!slideshow && (
        <Formik
          enableReinitialize
          initialValues={{
            name: slideshow.name || '',
            scss: slideshow.scss || '',
            section_id: slideshow.section_id,

            background_color: slideshow?.configuration?.background_color,
            background_image_url: slideshow?.configuration?.background_image_url,
            footer_background_color: slideshow?.configuration?.footer_background_color,
            footer_logo_url: slideshow?.configuration?.footer_logo_url,
            footer_text: slideshow?.configuration?.footer_text,
            footer_text_color: slideshow?.configuration?.footer_text_color,
            hide_captions: slideshow?.configuration?.hide_captions,
            only: slideshow?.configuration?.only,
            play_videos: slideshow?.configuration?.play_videos,
            post_count: slideshow?.configuration?.post_count,
            post_shown_for_seconds: slideshow?.configuration?.post_shown_for_seconds,
            post_timestamp_visible: slideshow?.configuration?.post_timestamp_visible,
            rotates: slideshow?.configuration?.rotates,
            show_stats: slideshow?.configuration?.show_stats,
            show_video_captions: slideshow?.configuration?.show_video_captions,
            tags: slideshow?.configuration?.tags,
            video_max_duration_in_seconds: slideshow?.configuration?.video_max_duration_in_seconds,
            embedUrlAliasEnabled: siteSettings?.embed_url_alias_enabled || false,
          }}
          onSubmit={(values) => {
            saveEditSlideshow(values);
            setEditVersion(editVersion + 1);
          }}
        >
          {(formikProps) => (
            <>
              <Heading level="h2" type="tertiary">
                <span className="inline-block w-full text-center">
                  Open the link below on a device connected to a digital screen
                </span>
              </Heading>

              <div className={styles.slideshowUrl}>
                <a
                  className={styles.slideshowUrlLink}
                  href={slideshow.shortcut_url}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {slideshow.shortcut_url} <Icon className={styles.slideshowUrlLinkIcon} type="external-link" />
                </a>
              </div>

              <ScreenPreview
                editVersion={editVersion}
                editValues={formikProps.values}
                hasUnsavedChanges={formikProps.dirty}
                isSaving={isSaving}
                onSave={formikProps.handleSubmit}
                display={slideshow}
                displayUpdateError={slideshowUpdateError}
              />

              <SlideshowEditForm
                editValues={formikProps.values}
                handleChange={formikProps.handleChange}
                handleSubmit={formikProps.handleSubmit}
                isDirty={formikProps.dirty}
                isNew={isNew}
                isSaving={isSaving}
                isValid={formikProps.isValid}
                setFieldValue={formikProps.setFieldValue}
                formErrors={formikProps.errors}
                slideshowUpdateError={slideshowUpdateError}
                submitForm={formikProps.submitForm}
                embedUrlAliasEnabled={siteSettings?.embed_url_alias_enabled}
              />
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingSlideshow: getSlideshowsLoadingState(state),
  isSaving: getSlideshowUpdatingState(state),
  slideshow: getEditSlideshow(state),
  slideshowUpdateError: getSlideshowUpdateError(state),
  siteSettings: getSiteSettings(state),
});

const mapDispatchToProps = { fetchSlideshows, saveEditSlideshow };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(SlideshowEditView);
