import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Link, useHistory } from 'react-router-dom';
import WebComponent from 'utils/web-component';

import useSiteUrl from 'hooks/useSiteUrl';
import { pathToDisplay, pathToChooseSection, pathToChooseDisplayType, pathToNewsletterEmbed } from 'services/routes';
import Page from 'components/Page';
import { getUserFirstName } from 'concepts/user';
import DisplayOption from '../components/DisplayOption';
import { getCurrentSiteId, getSitesLoadingStatus } from 'concepts/site';
import {
  initNewDisplayView,
  onCreate,
  getDisplayList,
  getDisplayListLoadingStatus,
  getPreviewArticlesLoading,
  DisplayType,
} from 'concepts/display-list';
import useSections from 'hooks/api/useSections';
import { CAROUSEL, GRID, WALL, SLIDESHOW } from 'constants/display-types';
import Heading from 'components/Heading';
import AnimateFadeIn from 'components/AnimateFadeIn';
import PageLoader from 'components/Loader/PageLoader';
import AngleLink from 'components/AngleLink';
import Icon from 'components/Icon';
import { cioTrackEvent } from 'utils/customer-io';


type NewDisplayViewProps = ConnectedProps<typeof connector>;

const NewDisplayView = ({
  siteId,
  displays,
  initNewDisplayView,
  isLoadingDisplays,
  isLoadingPreviewArticles,
  isLoadingSite,
  onCreate,
  userFirstName,
}: NewDisplayViewProps) => {
  const { sections } = useSections(siteId, { pageSize: null }, { revalidateIfStale: false });
  // we need extra flag because useEffect does not fire instantly...
  const [hasStartedView, setStarted] = useState(false);
  // store temporarily selected layout when creating new embed/slideshow
  const [selectedLayout, setSelectedLayout] = useState<DisplayType | undefined>(undefined);
  useEffect(() => {
    if (siteId) {
      setStarted(true);
      initNewDisplayView();
    }
  }, [initNewDisplayView, siteId]);
  const siteUrl = useSiteUrl();
  const history = useHistory();

  const isContentReady = hasStartedView && !!siteId && !isLoadingSite && !isLoadingPreviewArticles;
  const isLoaderVisible = !siteId || isLoadingSite || isLoadingPreviewArticles || isLoadingDisplays;
  const isDisplayOptionsVisible = isContentReady;
  const isBackButtonVisible = displays.length > 0;

  const onLayoutSelect = (type: DisplayType) => {
    // prevent duplicate creations
    if (selectedLayout) {
      return;
    }

    cioTrackEvent(`Layout type selected: ${type}`);

    if (type === SLIDESHOW || type === WALL) {
      return history.push(pathToChooseDisplayType(siteUrl, type));
    }

    if (sections && sections.length > 1) {
      return history.push(pathToChooseSection(siteUrl, type, 'embed'));
    }

    setSelectedLayout(type);

    onCreate({ displayType: type, sections }).catch(() => {
      setSelectedLayout(undefined);
    });
  };

  return (
    <Page className="!max-w-6xl !py-10">
      <Helmet>
        <title>Flockler {'\u203A'} New Embed</title>
      </Helmet>

      {isDisplayOptionsVisible && (
        <div className="space-y-5">
          <AnimateFadeIn animationDelay="0">
            <div className="mx-auto max-w-2xl xl:max-w-none">
              <Heading type="primary" level="h1" className="!m-0">
                Select a layout to embed on the website or display on a&nbsp;digital&nbsp;screen
              </Heading>
            </div>
          </AnimateFadeIn>

          <AnimateFadeIn animationDelay="400">
            <p className="text-center text-slate-600 md:text-lg">
              No stress {userFirstName ? ` ${userFirstName}` : ''}, you can create as many&nbsp;as&nbsp;you&nbsp;like.
            </p>
          </AnimateFadeIn>

          <div
            data-testid="display-options"
            className="mx-auto grid max-w-sm grid-cols-1 gap-2 sm:max-w-3xl sm:grid-cols-2 lg:max-w-none lg:grid-cols-4"
          >
            <DisplayOption
              onSelect={onLayoutSelect}
              title="Social Wall"
              type={WALL}
              isCreating={selectedLayout === WALL}
              description="Social Wall is perfect for organizations who would like to add a social media feed from multiple social media
          channels to their website."
            />

            <DisplayOption
              onSelect={onLayoutSelect}
              title="Grid"
              type={GRID}
              isCreating={selectedLayout === GRID}
              description="Grid layout can display a mix of social media feeds from various channels, but each element’s size is fixed to
          keep the look and feel nice and neat."
            />

            <DisplayOption
              onSelect={onLayoutSelect}
              title="Carousel"
              type={CAROUSEL}
              isCreating={selectedLayout === CAROUSEL}
              description="Carousel of the latest social media posts is an excellent option for your homepage or product pages when you
          don’t have much of vertical space available."
            />

            <DisplayOption
              onSelect={onLayoutSelect}
              title="Slideshow"
              type={SLIDESHOW}
              isCreating={selectedLayout === SLIDESHOW}
              description="A stunning Slideshow at the office lobby, in-store displays, and on a website keeps visitors engaged with your content."
            />
          </div>

          <AnimateFadeIn animationDelay="800">
            <div className="flex items-center justify-center">
              <Link to={pathToNewsletterEmbed(siteUrl)}>
                <WebComponent tag="fl-button" variant="secondary">
                  <Icon
                    type="envelope-check"
                    className="relative top-1 flex-shrink-0 scale-125 transform opacity-75 sm:top-0"
                  />
                  <AngleLink>I want to embed Flockler in an email newsletter</AngleLink>
                </WebComponent>
              </Link>
            </div>
          </AnimateFadeIn>

          {isBackButtonVisible && (
            <AnimateFadeIn animationDelay="900">
              <div className="text-center font-medium">
                <Link
                  to={pathToDisplay(siteUrl)}
                  className="rounded-md px-2 focus:no-underline focus:outline-none focus-visible:ring-1 focus-visible:ring-brand focus-visible:ring-offset-4"
                >
                  <AngleLink reverse>Back to layouts</AngleLink>
                </Link>
              </div>
            </AnimateFadeIn>
          )}
        </div>
      )}

      {isLoaderVisible && <PageLoader />}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  siteId: getCurrentSiteId(state),
  displays: getDisplayList(state),
  isLoadingDisplays: getDisplayListLoadingStatus(state),
  isLoadingPreviewArticles: getPreviewArticlesLoading(state),
  isLoadingSite: getSitesLoadingStatus(state),
  userFirstName: getUserFirstName(state),
});

const mapDispatchToProps = { initNewDisplayView, onCreate };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(NewDisplayView);
