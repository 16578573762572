import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';

import { getCurrentSiteState } from 'concepts/site';
import { getReviewsServiceDetailsState, fetchReviewsServiceDetails } from 'concepts/reviews-service';
import { fetchDataProcessingAgreementType, scheduleSiteFormAssetExport } from 'services/api';
import { pathToReviewsNewForm } from 'services/routes';

// Hooks
import useSections from 'hooks/api/useSections';
import useQuery from 'hooks/useQuery';
import { useReviewFormList } from './hooks/useReviewForms';

// Components
import Heading from 'components/Heading';
import Page from 'components/Page';
import PageLoader from 'components/Loader/PageLoader';
import WebComponent from 'utils/web-component';

// View specific components
import FirstReviewFormView from './components/FirstReviewFormView';
import DpaConfirmation from './components/DpaConfirmation';
import ReviewFormsListItemDeleteModal from './components/ReviewFormsListItemDeleteModal';
import ReviewFormsListItem from './components/ReviewFormsListItem';
import { ReviewsHead } from './components/ReviewsHead';
import ReviewFormImage from 'images/reviews/review-form.png';

const Reviews = ({ site, reviewsServiceDetails }: { site: Site; reviewsServiceDetails: ReviewsServiceDetails }) => {
  const history = useHistory();
  const query = useQuery();
  const { sections } = useSections(site?.id);
  const [dpaStatus, setDpaStatus] = useState<DataProcessingAgreementStatus | null>(null);
  const firstFormName = query.get('name');

  const [reviewFormAboutToBeDeleted, setReviewFormAboutToBeDeleted] = useState<ReviewForm | null>(null);
  const [deletedReviewForms, setDeletedReviewForms] = useState<string[]>([]);
  const { data, isLoading, mutate } = useReviewFormList({ site, reviewsServiceDetails });

  const [reviewForms, setReviewForms] = useState<ReviewForm[] | null>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (site && dpaStatus === null) {
      fetchDataProcessingAgreementType(site.id, 'reviews')
        .then((res) => {
          setDpaStatus('signed');
        })
        .catch((err) => {
          if (err.response?.status === 404) setDpaStatus('unsigned');
        });
    }
  }, [site, dpaStatus]);

  useEffect(() => {
    if (dpaStatus === 'signed' && !reviewsServiceDetails && site) {
      dispatch(fetchReviewsServiceDetails(site.id));
    }
  }, [dispatch, site, reviewsServiceDetails, dpaStatus]);

  useEffect(() => {
    if (data) {
      setReviewForms(
        data.reviewForms
          .filter((form: ReviewForm) => {
            if (form.state === 'disabled') return false;
            return !deletedReviewForms.includes(form.uuid as string);
          })
          .reverse()
      );
    }
  }, [data, deletedReviewForms]);

  // If first form name given as query param ?name=xyz, redirect to new form view
  useEffect(() => {
    if (dpaStatus && dpaStatus !== 'unsigned' && !!firstFormName) {
      history.replace(pathToReviewsNewForm(site?.site_url, { name: firstFormName }));
    }
  }, [dpaStatus, firstFormName, site, history]);

  const deleteReviewForm = async (reviewForm: ReviewForm) => {
    // Optimistically remove from list
    setDeletedReviewForms([...deletedReviewForms, reviewForm.uuid as string]);
    setReviewFormAboutToBeDeleted(null);

    const response = await fetch(`${reviewsServiceDetails.apiBaseUrl}/${site.uuid}/forms/${reviewForm.uuid}`, {
      method: 'DELETE',
      mode: 'cors',
      headers: {
        Authorization: `Bearer ${reviewsServiceDetails.token}`,
      },
    });

    if (response.status === 200) {
      mutate();
    } else {
      // Display in list if delete failed
      setDeletedReviewForms(deletedReviewForms.filter((uuid: string) => uuid !== (reviewForm.uuid as string)));
      alert(`Could not delete review form ${reviewForm.name}`);
    }
  };

  const scheduleAssetsDownload = async (reviewForm: ReviewForm) => {
    await scheduleSiteFormAssetExport(site.id, reviewForm.uuid).then((res) => {
      alert(`Assets for form ${reviewForm.name} will be delivered to your email soon...`);
    }).catch((err) => {
      alert(`Error: Could not schedule assets download for form ${reviewForm.name}.`);
    });
  };

  if (!site) {
    return (
      <Page style={{ maxWidth: '56rem' }}>
        <Helmet>
          <title>Flockler {'\u203A'} Review Forms</title>
        </Helmet>
        <Heading level="h1" type="primary">
          Reviews
        </Heading>

        <PageLoader />
      </Page>
    );
  }

  return (
    <Page style={{ maxWidth: '56rem' }}>
      <ReviewsHead />

      {dpaStatus === null ? (
        <PageLoader />
      ) : (
        <>
          {dpaStatus === 'unsigned' && !firstFormName && !reviewForms?.length && <FirstReviewFormView />}

          {dpaStatus === 'unsigned' && (firstFormName || !!reviewForms?.length) && (
            <DpaConfirmation site={site} confirm={() => setDpaStatus('signed')} />
          )}

          {dpaStatus !== 'unsigned' && (
            <>
              <div className="mb-8 flex flex-col md:flex-row">
                <div className="animate-fade-in md:w-1/3">
                  <figure
                    className="relative rounded border border-slate-100 p-3 max-md:mx-auto max-md:mb-6 max-md:max-w-[65vw] max-md:!transform-none md:left-3"
                    style={{
                      transform: 'perspective(75vw) rotateY(13deg) rotate(-7deg) skew(-7deg, 5deg)',
                      boxShadow: '0 1px 2px rgba(0,0,0,.05), -5px 10px 35px rgba(229,237,245,.6)',
                    }}
                  >
                    <img src={ReviewFormImage} alt="Review form"></img>
                  </figure>
                </div>

                <div className="pt-4 max-md:text-center md:ml-12 md:w-2/3">
                  <p className="mb-6 text-sm md:mb-10 md:text-base">
                    Collect star ratings, text, images and videos with a form embedded on your website. Custom Forms enable
                    webshops to collect customer reviews, marketers to get more user-generated content, and event
                    organisers to allow visitors to share content without a social media account.
                  </p>
                  {!isLoading && (
                    <WebComponent tag="fl-button"
                        variant="success"
                        to={pathToReviewsNewForm(site.site_url)}
                      >
                          Create a new form
                    </WebComponent>
                  )}
                </div>
              </div>

              <div className="mt-16">
                {isLoading && <PageLoader />}

                <div className="space-y-5">
                  {!isLoading && reviewForms?.length === 0 && (
                    <div className="mt-20 rounded border border-dashed border-slate-300 px-5 py-10 text-center text-lg text-slate-400">
                      No custom forms created yet.
                    </div>
                  )}

                  {!!reviewForms?.length && (
                    <section>
                      {reviewForms.map((form: ReviewForm, index: number) => (
                        <ReviewFormsListItem
                          key={form.uuid}
                          index={index}
                          form={form}
                          site={site}
                          sections={sections}
                          reviewsServiceDetails={reviewsServiceDetails}
                          reviewFormIsAboutToBeDeleted={form.uuid === reviewFormAboutToBeDeleted?.uuid}
                          setReviewFormAboutToBeDeleted={setReviewFormAboutToBeDeleted}
                          scheduleAssetsDownload={scheduleAssetsDownload}
                        />
                      ))}
                    </section>
                  )}
                </div>
              </div>

              {reviewFormAboutToBeDeleted && (
                <ReviewFormsListItemDeleteModal
                  reviewForm={reviewFormAboutToBeDeleted}
                  dismissAction={() => setReviewFormAboutToBeDeleted(null)}
                  deleteAction={() => deleteReviewForm(reviewFormAboutToBeDeleted)}
                />
              )}
            </>
          )}
        </>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  site: getCurrentSiteState(state),
  reviewsServiceDetails: getReviewsServiceDetailsState(state),
});

const mapDispatchToProps = {
  fetchReviewsServiceDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
