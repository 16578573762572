import Icon from 'components/Icon';

import styles from './ReviewFormPreviewField.module.scss';
import ReviewFormFileField from "../ReviewFormFileField";

const ReviewFormPreviewField = ({
  form,
  label,
  type,
  helpText,
  limit,
  required,
  fieldId,
  focusedField,
}: {
  form: ReviewForm;
  label: React.ReactNode;
  type: ReviewFormFieldDetailTypes;
  helpText?: string;
  limit?: number;
  required?: boolean;
  fieldId: ReviewFormFieldIdentifier | 'submit';
  focusedField: ReviewFormFieldIdentifier | null;
}) => {
  const isFocused = focusedField === fieldId;
  const userIsFocusingField = Boolean(focusedField);

  if (type === 'checkbox') {
    return (
      <div
        className={`${styles.ReviewFormPreviewField} space-y-1 rounded-md border p-3 transition-opacity duration-500 ${
          userIsFocusingField
            ? isFocused
              ? 'border-brand opacity-100'
              : 'border-transparent opacity-30'
            : 'border-transparent'
        }`}
      >
        <div className="flex items-start">
          <div className="mr-2 h-5 w-5 min-w-[1.25rem] rounded-md border border-slate-300"></div>
          {['terms', 'checkbox'].includes(fieldId) ? (
            <span className="text-sm" dangerouslySetInnerHTML={{ __html: label as string }} />
          ) : (
            <span className="text-sm">{label}</span>
          )}
        </div>
      </div>
    );
  }

  if (type === 'display') {
    return (
      <div
        className={`space-y-1 rounded-md border px-3 pt-2 pb-3 transition-opacity duration-500 ${
          userIsFocusingField
            ? isFocused
              ? 'border-brand opacity-100'
              : 'border-transparent opacity-30'
            : 'border-transparent'
        }`}
      >
        <div className="flex select-none items-center p-5 justify-center space-x-2 rounded-md border border-slate-300 text-slate-400">
          <span className="text-sm" dangerouslySetInnerHTML={{ __html: label as string }} />
        </div>
      </div>
    );
  }

  if (type === 'file' && ['image', 'video'].includes(fieldId)) {
    const isImage = fieldId === 'image';
    return (
      <ReviewFormFileField
        label={label}
        required={required}
        isFocused={isFocused}
        userIsFocusingField={userIsFocusingField}
        maxFileSize={isImage ? '10 MB' : '100 MB'}
        instruction={
          form.config.fields[fieldId]?.helpText ||
          form.config.text[isImage ? 'imageInstruction' : 'videoInstruction']
        }
        iconType={isImage ? 'upload-image' : 'upload'}
      />
    );
  }

  return (
    <div
      className={`space-y-1 rounded-md border px-3 pt-2 pb-3 transition-opacity duration-500 ${
        userIsFocusingField
          ? isFocused
            ? 'border-brand opacity-100'
            : 'border-transparent opacity-30'
          : 'border-transparent'
      }`}
    >
      <div className="flex items-center justify-between">
        <span className="text-sm font-semibold">
          {label}
          {required && <span className="ml-1 opacity-50">*</span>}
        </span>
        {type === 'textarea' && <span className="select-none text-xs opacity-60">{limit && `0/${limit}`}</span>}
      </div>
      {type === 'text' && fieldId !== 'rating' && <div className="h-10 rounded-md border border-slate-300"></div>}
      {type === 'textarea' && <div className="h-28 rounded-md border border-slate-300"></div>}

      {fieldId === 'rating' && (
        <div className="opacity-30">
          <Icon type="star-outline" className="h-8 w-8" />
          <Icon type="star-outline" className="h-8 w-8" />
          <Icon type="star-outline" className="h-8 w-8" />
          <Icon type="star-outline" className="h-8 w-8" />
          <Icon type="star-outline" className="h-8 w-8" />
        </div>
      )}
      {helpText && <div className="text-smaller text-slate-600">{helpText}</div>}
    </div>
  );
};

ReviewFormPreviewField.displayName = 'ReviewFormPreviewField';
export default ReviewFormPreviewField;
