import { useCallback, useEffect, useRef, useState } from 'react';
import { driver, DriveStep } from 'driver.js';
import { useHistory } from 'react-router-dom';
import { pathToAutomatedFeeds, pathToDisplay, pathToNewDisplay, pathToSections } from 'services/routes';
import useSiteUrl from './useSiteUrl';
import { useAppDispatch } from './useAppDispatch';
import { initDisplayView } from 'concepts/display-list';
import config from 'config';
import { useCookies } from 'react-cookie';
import { completeOnboarding } from 'services/api';
import useSiteId from './useSiteId';
import { postHogTrackEvent } from 'utils/post-hog-sync';

type DriveStepWithStepName = DriveStep & { stepName: string };

const IN_PROGRESS = 'in-progress';
const CANCEL = 'cancel';
const DONE = 'done';

const onboardingEventName = (eventName: string) => `onboarding:app:${eventName}`;
const nextButtonClickEventName = (stepName: string) => onboardingEventName(`${stepName}_next_button_click`);

function hasStepName(step: DriveStep): step is DriveStepWithStepName {
  return 'stepName' in step;
}

function useOnboardingCookieKey() {
  const siteUrl = useSiteUrl();

  return `onboarding_${siteUrl}`;
}

function useMockOverlay() {
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const applyOverlay = useCallback(() => {
    if (overlayRef.current) return;

    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(40, 50, 70, 0.3)';
    overlay.style.zIndex = '9999';
    overlay.style.transition = 'opacity 0.2s ease-in-out';
    overlay.style.opacity = '1';

    document.body.appendChild(overlay);
    overlayRef.current = overlay;
  }, []);

  const removeOverlay = useCallback(() => {
    const overlay = overlayRef.current;

    if (overlay) {
      overlay.style.opacity = '0';
      setTimeout(() => overlay.remove(), 200);
    }
  }, []);

  return { applyOverlay, removeOverlay };
}

export function useOnboardingCheck() {
  const [active, setActive] = useState(false);
  const onboardingKey = useOnboardingCookieKey();
  const [cookies] = useCookies([onboardingKey]);
  const cookieValue = cookies[onboardingKey];

  useEffect(() => {
    if (active) return;

    setActive(cookieValue === IN_PROGRESS);
  }, [active, cookieValue]);

  return active;
}

function useOnboarding() {
  const onboardingInitialized = useRef(false);
  const onboardingKey = useOnboardingCookieKey();
  const [, setCookieValue] = useCookies([onboardingKey]);
  const history = useHistory();
  const siteUrl = useSiteUrl();
  const siteId = useSiteId();
  const dispatch = useAppDispatch();
  const { applyOverlay, removeOverlay } = useMockOverlay();

  useEffect(() => {
    applyOverlay();
  }, [applyOverlay]);

  const startOnboarding = useCallback(async () => {
    history.push(pathToSections(siteUrl));

    await pollForElement('ol[data-testid="sections-list"] > li');

    const isMobile = window.innerWidth <= 768;

    const steps: DriveStepWithStepName[] = [
      {
        stepName: 'sections',
        element: 'ol[data-testid="sections-list"] > li',
        popover: {
          onPrevClick: () => {}, // prevent the tour from exiting if backtracking on the first step
          onNextClick: (_, step, { driver }) => {
            if (hasStepName(step)) postHogTrackEvent(nextButtonClickEventName(step.stepName));

            driver.moveNext();
          },
          side: 'top',
          align: 'start',
          description:
            'Sections (or internal folders) on Flockler is where all aggregated posts are organized. Here’s the section with all the sample content we saw in the previous screen.',
        },
        onHighlighted: () => {
          dispatch(initDisplayView());
        },
      },
      {
        stepName: 'display_tab',
        element: !isMobile ? 'a[href$="/layouts"]' : '[data-testid="navigation"]',
        popover: {
          description: 'Next, let’s move on to the Display tab to design how your content will look on your website.',
          side: 'bottom',
          align: 'center',
          onNextClick: async (_, step, { driver }) => {
            if (hasStepName(step)) postHogTrackEvent(nextButtonClickEventName(step.stepName));

            history.push(pathToNewDisplay(siteUrl));

            const elementToPoll = !isMobile
              ? '[data-testid="display-options"]'
              : '[data-testid="display-options"] > button:first-of-type';

            await pollForElement(elementToPoll);

            window.requestAnimationFrame(() => driver.moveNext());
          },
        },
      },

      {
        stepName: 'layout_options',
        element: !isMobile
          ? '[data-testid="display-options"]'
          : '[data-testid="display-options"] > button:first-of-type',
        popover: {
          onPrevClick: async (_, __, { driver }) => {
            history.goBack();
            await pollForElement('ol[data-testid="sections-list"]');
            driver.movePrevious();
          },
          onNextClick: async (_, step, { driver }) => {
            if (hasStepName(step)) postHogTrackEvent(nextButtonClickEventName(step.stepName));

            history.push(pathToDisplay(siteUrl));
            await pollForElement('[data-testid="display-list-item"]');
            driver.moveNext();
          },
          description:
            'On the Display tab you can choose a layout for your posts from our 4 preset styles - Grids, Walls, Carousels, and Slideshows.<br /><br /><strong>Pro tip:</strong> Walls and Slideshows work great for both websites and digital screens.',
          side: 'bottom',
          align: 'center',
        },
      },

      {
        stepName: 'layout_item',
        element: '[data-testid="display-list-item"]',
        popover: {
          onPrevClick: async (_, __, { driver }) => {
            history.goBack();
            await pollForElement('[data-testid="display-options"]');
            driver.movePrevious();
          },
          description: 'I’ve created a Wall layout for you – remember that you can have as many layouts as you want.',
          side: 'top',
          align: 'center',
        },
      },
      {
        stepName: 'layout_edit_link',
        element: '[data-testid="display-list-item"] [data-testid="edit-link"]',
        popover: {
          description: 'Here you can customize the look of your layout.',
          side: 'top',
          align: 'center',
        },
      },
      {
        stepName: 'layout_preview_link',
        element: '[data-testid="display-list-item"] [data-testid="preview-link"]',
        disableActiveInteraction: false,
        popover: {
          description: 'Here you can preview your layout to see how it looks with your content.',
          side: 'top',
          align: 'center',
        },
      },
      {
        stepName: 'layout_embed_code',
        element: '[data-testid="display-list-item"] [data-testid="embed-code-copier"]',
        disableActiveInteraction: false,
        popover: {
          description:
            'Now paste this embed code on your website to bring your wall to life. Any tweaks made to your layout here is directly reflected on your live wall - no coding needed!',
          side: 'left',
          align: 'center',
        },
      },

      {
        stepName: 'complete',
        popover: {
          description:
            '<center><strong class="block mb-2 text-base">You’ve completed the tour! 🎉</strong>Want to create a Feed on your own now?\n\n</center>',
          doneBtnText: 'Let’s do it!',
          prevBtnText: 'No thanks',
          onPrevClick: (_, __, { driver }) => {
            postHogTrackEvent(onboardingEventName('no_thanks_button_click'));
            driver.destroy();
          },
          onNextClick: async () => {
            await postHogTrackEvent(onboardingEventName('create_feed_button_click'));
            window.location.href = `${config.flocklerFeedsUrl}/${siteUrl}`;
          },
        },
        onHighlighted: () => {
          setCookieValue(onboardingKey, DONE, { path: '/', domain: config.flocklerBaseDomain });
          completeOnboarding(siteId);
        },
      },
    ];

    const driverInstance = driver({
      steps,
      showProgress: false,
      popoverClass: 'onboarding-tour',
      overlayColor: 'rgb(40, 50, 70)',
      overlayOpacity: 0.3,
      disableActiveInteraction: true,
      allowClose: false,
      onPopoverRender: (popover) => {
        popover.wrapper.appendChild(createBotIcon());

        // focus the next button when the popover is rendered
        if (popover.nextButton && !popover.nextButton.getAttribute('disabled')) {
          window.requestAnimationFrame(() => {
            popover.nextButton.focus();
          });
        }
      },
      onHighlightStarted: (_, step) => {
        if (hasStepName(step)) postHogTrackEvent(onboardingEventName(`${step.stepName}_view`));
      },
      onNextClick: (_, step, { driver }) => {
        if (hasStepName(step)) postHogTrackEvent(nextButtonClickEventName(step.stepName));

        driver.moveNext();
      },
      onDestroyStarted: (_, step, { driver }) => {
        // if the user cancels the tour, we should mark it as cancelled
        if (!driver.isLastStep()) {
          const stepName = hasStepName(step) ? step.stepName : 'unknown';
          postHogTrackEvent('onboarding:tour_cancel', { app: 'app', step: stepName });
          setCookieValue(onboardingKey, CANCEL, { path: '/', domain: config.flocklerBaseDomain });
          completeOnboarding(siteId);
          history.push(pathToAutomatedFeeds(siteUrl));
        }

        driver.destroy();
      },
    });

    removeOverlay();
    driverInstance.drive();
  }, [siteUrl, siteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkDependencies = useCallback(() => {
    if (onboardingInitialized.current) return;

    if (siteUrl && siteId) {
      onboardingInitialized.current = true;
      startOnboarding();
    }
  }, [siteUrl, siteId, startOnboarding]);

  return checkDependencies;
}

function pollForElement(selector: string, timeout = 5000): Promise<void> {
  return new Promise((resolve) => {
    const interval = 100;
    const timer = setInterval(() => {
      const element = document.querySelector(selector);

      if (element) {
        clearInterval(timer);
        resolve();
      }

      timeout -= interval;

      if (timeout <= 0) {
        clearInterval(timer);
        resolve();
      }
    }, interval);
  });
}

function createBotIcon() {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('viewBox', '0 0 359 359');
  svg.setAttribute('class', 'onboarding__bot-icon');
  svg.innerHTML =
    '<linearGradient id="a" x1="19.285%" x2="61.477%" y1="0%" y2="81.08%"><stop offset="0" stop-color="#3e95ee"/><stop offset="1" stop-color="#225ed2"/></linearGradient><g fill="none" fill-rule="evenodd"><circle cx="179.5" cy="179.5" fill="url(#a)" r="179.5"/><g fill="#fff" transform="translate(57 35)"><path d="m0 151.935919c2.64185367-70.6239459 44.1418537-105.935919 124.5-105.935919 80.358146 0 121.858146 35.3119731 124.5 105.935919 0 54.213752-25.445985 86.393051-76.337956 96.537899-35.579799 7.092507-68.120537 7.638914-103.7128123 0-45.9661545-9.865384-68.9492317-42.044684-68.9492317-96.537899zm23 1.998353c0 23.080277 18.644906 38.43552 55.9347181 46.065728 28.8740549 5.908183 55.2725649 5.485575 84.1364989 0 41.285855-7.846354 61.928783-23.201597 61.928783-46.065728-2.143191-54.6228479-35.809857-81.934272-101-81.934272-65.1901428 0-98.8568095 27.3114241-101 81.934272z"/><circle cx="85" cy="16" r="16"/><path d="m94.447 8.166h6v51h-6z" transform="matrix(.79863551 -.60181502 .60181502 .79863551 -.638339 65.424205)"/><rect height="60" rx="12" width="24" x="87" y="109"/><rect height="60" rx="12" width="24" x="137" y="109"/></g></g>';

  return svg;
}

export default useOnboarding;
