import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from 'redux/store';
import { Helmet } from 'react-helmet';
import { Formik } from 'formik';

import { getCurrentSite, getCurrentSiteId, getSitesLoadingStatus, getSiteUuid, getSiteSettings } from 'concepts/site';
import { getEmbedsLoadingState, getEmbedUpdatingState, getEmbedUpdateError } from 'concepts/embed';
import { getEmbedThemes } from 'concepts/embed-theme';
import { getStyleName } from 'concepts/display-list';
import { getEditEmbed, saveEditEmbed, fetchEmbeds } from 'concepts/embed/edit';
import { fetchEmbedThemes } from 'concepts/embed-theme';
import { getUserProfile } from 'concepts/user';

import Heading from 'components/Heading';
import NoticeBox from 'components/NoticeBox';
import WebComponent from 'utils/web-component';
import LoadingIndicator from 'components/Loader/LoadingIndicator';
import Page from 'components/Page';
import WallVersionSwitcher from '../components/WallVersionSwitcher';

import EmbedEditForm from '../components/EmbedEditForm';
import EmbedCodePreview from '../components/EmbedCodePreview';
import EmbedPreview from '../components/EmbedPreview';

import styles from './EmbedEditView.module.scss';
import { site } from 'redux/reducers';

type EmbedEditViewProps = ConnectedProps<typeof connector>;

const EmbedEditView = ({
  currentSiteId,
  embedThemes,
  fetchEmbeds,
  fetchEmbedThemes,
  isLoadingSite,
  isLoadingEmbed,
  isSavingEmbed,
  embed,
  embedUpdateError,
  saveEditEmbed,
  siteUuid,
  site,
  user,
  siteSettings,
}: EmbedEditViewProps) => {
  useEffect(() => {
    if (currentSiteId) {
      fetchEmbeds();
      fetchEmbedThemes();
    }
  }, [currentSiteId, fetchEmbeds, fetchEmbedThemes]);
  const [editVersion, setEditVersion] = useState(0);
  const styleName = embed ? getStyleName(embed) : 'Embed';
  const pageTitle = `Edit ${styleName}`;
  const isLoadingView = !currentSiteId || isLoadingSite || isLoadingEmbed;
  const embedCodeRef = React.createRef<HTMLDivElement>();

  const isWallV1 = embed?.configuration.style === 'wall_v1' || embed?.configuration.style === 'wall';
  const [isWallVersionSwitcherVisible, setIsWallVersionSwitcherVisible] = useState(false);
  const [selectedCodeType, setSelectedCodeType] = useState<EmbedCodeType>('default');
  const [isIframeCopyModalOpen, setIsframeCopyModalOpen] = useState(false);

  const upgradeToWallV2 = (embed: Embed) => {
    const newConf = {
      show_profile: !embed.configuration.extra?.wall_hide_footer,
      style: 'wall_v2',
    };

    return saveEditEmbed(newConf);
  };

  return (
    <Page className={styles.editorPage}>
      <Helmet>
        <title>
          Flockler {'\u203A'} {pageTitle}
        </title>
      </Helmet>
      <Heading level="h1" type="primary">
        {pageTitle}
      </Heading>
      {isLoadingView && (
        <div className={styles.loader}>
          <LoadingIndicator />
        </div>
      )}
      {!isLoadingView && !embed && <p>Error loading embed...</p>}
      {!isLoadingView && !!embed && isWallV1 && currentSiteId && (
        <>
          <div className="mb-8 text-center">
            <NoticeBox type="neutral">
              <strong className="mr-4">There’s a new and improved version of the Social Wall layout available.</strong>
              <WebComponent
                tag="fl-button"
                size="small"
                variant="success"
                onClick={() => setIsWallVersionSwitcherVisible(true)}
              >
                Upgrade this embed for free
              </WebComponent>
            </NoticeBox>
          </div>
          {isWallVersionSwitcherVisible && (
            <WallVersionSwitcher
              user={user}
              siteId={currentSiteId}
              hideAction={() => setIsWallVersionSwitcherVisible(false)}
              updateSingleWall
              upgradeAction={() => upgradeToWallV2(embed)}
            />
          )}
        </>
      )}
      {!isLoadingView && !!embed && (
        <Formik
          enableReinitialize
          initialValues={{
            name: embed.name || '',
            original_css: embed.original_css || '',
            embed_theme_id: embed.embed_theme_id,
            url_alias: embed?.url_alias,
            embedUrlAliasEnabled: siteSettings?.embed_url_alias_enabled,

            // Rest is configuration
            autoplay: embed?.configuration?.autoplay,
            count: embed?.configuration?.count,
            extra: embed?.configuration?.extra || {},
            infinite_scroll: embed?.configuration?.infinite_scroll,
            load_more_text: embed?.configuration?.load_more_text,
            locale: embed?.configuration?.locale,
            only: embed?.configuration?.only,
            post_shown_for_seconds: embed?.configuration?.post_shown_for_seconds,
            product_category_ids: embed?.configuration?.product_category_ids,
            product_feed_ids: embed?.configuration?.product_feed_ids,
            product_ids: embed?.configuration?.product_ids,
            product_show_all_fallback: embed?.configuration?.product_show_all_fallback,
            refresh_type: embed?.configuration?.refresh_type,
            refresh: embed?.configuration?.refresh,
            section_ids: embed?.configuration?.section_ids,
            show_comments: embed?.configuration?.show_comments,
            show_likes: embed?.configuration?.show_likes,
            show_more_text: embed?.configuration?.show_more_text,
            show_profile: embed?.configuration?.show_profile,
            show_retweets: embed?.configuration?.show_retweets,
            style: embed?.configuration?.style,
            tags: embed?.configuration?.tags,

            // Create and preview theme colors
            theme_css_variables: {
              '--fl-background': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
                '--fl-background'
              ],
              '--fl-item-background': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
                '--fl-item-background'
              ],
              '--fl-text-color': embedThemes.find((theme) => theme.id === embed.embed_theme_id)?.css_variables?.[
                '--fl-text-color'
              ],
              ...(embed?.configuration?.style === 'slideshow'
                ? {
                    '--fl-navigation-arrow-color': embedThemes.find((theme) => theme.id === embed.embed_theme_id)
                      ?.css_variables?.['--fl-navigation-arrow-color'],
                  }
                : {}),
            },
          }}
          onSubmit={(values) => {
            return saveEditEmbed(values).then(() => setEditVersion(editVersion + 1));
          }}
        >
          {(formikProps) => (
            <>
              <Heading level="h2" type="tertiary">
                Add this embed code to any website or digital service
              </Heading>

              <EmbedCodePreview
                embedCodeRef={embedCodeRef}
                embedStyleName={getStyleName(embed)}
                embedUrl={embed.url}
                embedUuid={embed.uuid}
                siteUuid={siteUuid}
                isIframeCopyModalOpen={isIframeCopyModalOpen}
                setIsframeCopyModalOpen={setIsframeCopyModalOpen}
                selectedCodeType={selectedCodeType}
                setSelectedCodeType={setSelectedCodeType}
                embedConfig={formikProps.values}
                siteUrl={site.site_url}
                embedUrlAlias={embed.url_alias}
                embedAliasedUrl={embed.aliased_url}
                embedUrlAliasEnabled={siteSettings?.embed_url_alias_enabled}
              />

              <EmbedPreview
                editValues={formikProps.values}
                editVersion={editVersion}
                embed={embed}
                embedCodeRef={embedCodeRef}
                embedUpdateError={embedUpdateError}
                hasUnsavedChanges={formikProps.dirty}
                isSaving={isSavingEmbed}
                saveEmbed={formikProps.handleSubmit}
                selectedCodeType={selectedCodeType}
                setIsframeCopyModalOpen={setIsframeCopyModalOpen}
                embedUrlAliasEnabled={siteSettings?.embed_url_alias_enabled}
              />

              <EmbedEditForm
                editValues={formikProps.values}
                embed={embed}
                embedUpdateError={embedUpdateError}
                formErrors={formikProps.errors}
                handleChange={formikProps.handleChange}
                handleSubmit={formikProps.handleSubmit}
                isDirty={formikProps.dirty}
                isValid={formikProps.isValid}
                isSaving={isSavingEmbed}
                setFieldValue={formikProps.setFieldValue}
                submitForm={formikProps.submitForm}
                embedUrlAliasEnabled={siteSettings?.embed_url_alias_enabled}
              />
            </>
          )}
        </Formik>
      )}
    </Page>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentSiteId: getCurrentSiteId(state),
  isLoadingSite: getSitesLoadingStatus(state),
  isLoadingEmbed: getEmbedsLoadingState(state),
  isSavingEmbed: getEmbedUpdatingState(state),
  embed: getEditEmbed(state),
  embedThemes: getEmbedThemes(state),
  embedUpdateError: getEmbedUpdateError(state),
  siteUuid: getSiteUuid(state),
  user: getUserProfile(state),
  site: getCurrentSite(state),
  siteSettings: getSiteSettings(state),
});

const mapDispatchToProps = { fetchEmbeds, fetchEmbedThemes, saveEditEmbed };

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(EmbedEditView);
