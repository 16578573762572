import React, { useMemo, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/store';
import { NavLink } from 'react-router-dom';
import { useOnClickOutside, useWindowSize } from 'usehooks-ts';
import isEmpty from 'lodash/isEmpty';
import config from 'config';
import {
  pathToAnalytics,
  pathToAutomatedFeeds,
  pathToCommerce,
  pathToDisplay,
  pathToReviews,
  pathToSections,
  pathToSettings,
  pathToStreams,
  pathToDashboard,
  pathToRights,
} from 'services/routes';
import { ReactComponent as CaretDownIcon } from 'images/icons/icon-caret-down.svg';
import styles from './Navigation.module.scss';
import { getSiteUrl, getCurrentSite, getSiteSettings, getSitesLoadingStatus } from 'concepts/site';
import { getUserSiteRoles, isAdminUser } from 'concepts/user';
import Icon from 'components/Icon';

// # <NavigationItem />
type NavigationItemProps = {
  href?: string;
  to?: any;
  id: string;
  shouldRender?: boolean;
  hideOnMobile?: boolean;
  label: React.ReactNode | string;
  forceReload?: boolean;
  anchorProps?: React.HTMLAttributes<HTMLAnchorElement>;
};

const renderNavigationItem = ({
  href,
  id,
  label,
  to,
  hideOnMobile,
  shouldRender,
  forceReload = false,
  anchorProps: { dangerouslySetInnerHTML, ...anchorProps } = {},
}: NavigationItemProps) => {
  if (!shouldRender) {
    return null;
  }

  // Anchor links to outside
  if (href) {
    return (
      <a
        key={id}
        href={href}
        className={styles.navItem}
        data-nav-item={id}
        data-hide-on-mobile={hideOnMobile}
        {...anchorProps}
      >
        <span className={styles.navItemLabel}>{label}</span>
      </a>
    );
  }

  // React Router links inside app
  return (
    <NavLink
      key={id}
      to={to}
      className={styles.navItem}
      activeClassName={styles.navItemActive}
      data-nav-item={id}
      data-hide-on-mobile={hideOnMobile}
      onClick={(e) => {
        if (forceReload) {
          e.preventDefault();
          e.stopPropagation();
          window.location.href = `https://${window.location.host}${to}`;
        }
      }}
      exact={id === 'dashboard'}
      {...anchorProps}
    >
      <span className={styles.navItemLabel}>{label}</span>
    </NavLink>
  );
};

// # <Navigation />
type NavigationProps = {
  isAdmin: boolean;
  isLoadingSite: boolean;
  settings?: SiteSettings;
  site?: Partial<Site>;
  siteUrl?: string;
  userSiteRoles: UserRole[];
};

const Navigation = ({ isAdmin, isLoadingSite, settings, site, siteUrl, userSiteRoles }: NavigationProps) => {
  const [dropdownLinks, setDropdownLinks] = useState<string[]>([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { width: windowWidth } = useWindowSize();
  const navRef = useRef(null as HTMLDivElement | null);
  const navRefElem = navRef.current;

  const roleName = userSiteRoles?.[0]?.role_name;
  const isAuthorizedToAdminLink = isAdmin || roleName === 'owner' || roleName === 'manager';
  const siteType = site?.site_type;
  const isIssueSite = siteType === 'issue';
  const isStreamSite = siteType === 'stream';
  const isEmbedSite = siteType === 'embed';
  const isReady = !isLoadingSite && !!settings;
  const isUsingFeedsApp = ['react', 'feeds'].includes(settings?.cms_type || '');

  const [forceReload, setForceReload] = useState(false);

  // Make nav links to be cause page reload after a specific time
  useEffect(() => {
    const THREE_HOURS = 1000 * 60 * 60 * 3;
    const timer = setTimeout(() => {
      setForceReload(true);
    }, THREE_HOURS);
    return () => clearTimeout(timer);
  }, []);

  const navItems = useMemo<NavigationItemProps[]>(
    () =>
      siteUrl
        ? [
            {
              shouldRender: isEmbedSite && isAuthorizedToAdminLink,
              to: pathToDashboard(siteUrl),
              id: 'dashboard',
              label: (
                <>
                  <Icon type="home" title="Home" className="-mt-1" />
                  <span>Home</span>
                </>
              ),
              anchorProps: {
                'aria-label': 'Home',
              },
            },

            {
              shouldRender: isIssueSite,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/issues`,
              id: 'issues',
              label: 'Issues',
            },

            {
              shouldRender: isStreamSite,
              to: pathToStreams(siteUrl as string),
              id: 'streams',
              label: 'Streams',
            },

            {
              shouldRender: !isIssueSite && !isStreamSite && !isEmpty(site?.sections),
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/sections/first`,
              id: 'content',
              label: 'Content',
            },

            {
              shouldRender: !isIssueSite && !isStreamSite,
              to: pathToSections(siteUrl as string),
              id: 'sections',
              label: 'Sections',
            },
            {
              shouldRender: settings?.embed_code_helper_enabled && isAuthorizedToAdminLink,
              to: pathToDisplay(siteUrl as string),
              id: 'display',
              label: 'Display',
            },

            {
              shouldRender: settings?.page_editor_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/pages`,
              id: 'static-pages',
              label: 'Pages',
            },

            {
              shouldRender: settings?.author_editor_enabled,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/authors`,
              id: 'authors',
              label: 'Authors',
            },

            {
              shouldRender: settings?.events_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/events`,
              id: 'events',
              label: 'Events',
            },

            {
              shouldRender: settings?.menu_editor_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/menus`,
              id: 'menus',
              label: 'Menus',
            },

            {
              shouldRender: settings?.featured_articles_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/featured`,
              id: 'featured-articles',
              label: 'Featured Articles',
            },

            {
              shouldRender: settings?.scheduled_features_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/featured/scheduled`,
              id: 'scheduled-features',
              label: 'Scheduled features',
            },

            {
              shouldRender: settings?.featured_events_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/featured/events`,
              id: 'featured-events',
              label: 'Featured Events',
            },

            {
              shouldRender: settings?.featured_recipes_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/featured/recipes`,
              id: 'featured-recipes',
              label: 'Featured Recipes',
            },

            {
              shouldRender: settings?.featured_authors_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/featured/authors`,
              id: 'featured-authors',
              label: 'Featured Authors',
            },

            {
              shouldRender: settings?.newsroom_show_contacts && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/forms`,
              id: 'forms',
              label: 'Forms',
            },

            {
              shouldRender: settings?.media_trackers_enabled && isAuthorizedToAdminLink && isUsingFeedsApp,
              to: pathToAutomatedFeeds(siteUrl),
              id: 'feeds',
              label: 'Feeds',
            },

            {
              shouldRender: settings?.reviews_enabled && isAuthorizedToAdminLink,
              hideOnMobile: true,
              to: pathToReviews(siteUrl),
              id: 'reviews',
              label: 'Custom forms',
            },

            {
              shouldRender: settings?.media_trackers_enabled && isAuthorizedToAdminLink && !isUsingFeedsApp,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/media-trackers`,
              id: 'feeds-newsroom',
              label: 'Feeds',
            },

            {
              shouldRender:
                settings?.social_analytics_ui_enabled &&
                !settings?.embed_code_helper_enabled &&
                isAuthorizedToAdminLink,
              href: `${config.analyticsAppUrl}/${siteUrl}`,
              id: 'analytics-direct',
              label: 'Analytics',
            },

            {
              shouldRender: settings?.embed_code_helper_enabled && isAuthorizedToAdminLink,
              to: pathToAnalytics(siteUrl as string),
              id: 'analytics',
              label: 'Analytics',
            },

            {
              shouldRender: settings?.commerce_enabled && isAuthorizedToAdminLink,
              hideOnMobile: true,
              href: `${config.commerceAppUrl}/${siteUrl}`,
              id: 'commerce',
              label: 'Commerce',
            },

            {
              shouldRender: isEmbedSite && !settings?.commerce_enabled && isAuthorizedToAdminLink,
              hideOnMobile: true,
              to: pathToCommerce(siteUrl),
              id: 'commerce-landing',
              label: 'Commerce',
            },

            {
              shouldRender: settings?.new_newsletter_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/newsletter`,
              id: 'newsletter',
              label: 'Newsletter',
            },

            {
              shouldRender: settings?.form_editor_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/diy-forms`,
              id: 'diy-forms',
              label: 'Wufoo Forms',
            },

            {
              shouldRender: settings?.customizer_enabled && isAuthorizedToAdminLink,
              href: `${config.flocklerNewsroomUrl}/${siteUrl}/customize`,
              id: 'customize',
              label: 'Customize',
            },

            {
              shouldRender: settings?.rights_management_enabled && isAuthorizedToAdminLink,
              hideOnMobile: true,
              to: pathToRights(siteUrl),
              id: 'rights-landing',
              label: 'UGC Rights',
            },

            {
              shouldRender: isAuthorizedToAdminLink,
              to: pathToSettings(siteUrl as string),
              id: 'settings',
              label: (
                <>
                  <Icon type="cog" title="Settings" />
                  <span>Settings</span>
                </>
              ),
              anchorProps: {
                'aria-label': 'Settings',
              },
            },
          ]
        : [],
    [isEmbedSite, isIssueSite, isUsingFeedsApp, isStreamSite, site, siteUrl, isAuthorizedToAdminLink, settings]
  );

  // Check if collapse menu is needed
  useEffect(() => {
    if (navRefElem) {
      const links = navRefElem.querySelectorAll('a');
      const invisibleLinks = [];

      // Get links that wrap to second row
      for (let i = 0; i < links.length; i++) {
        if (links[i].offsetTop > 0) {
          invisibleLinks.push(links[i].getAttribute('data-nav-item') as string);
        }
      }

      // Update state
      setDropdownLinks(invisibleLinks);
    }
  }, [windowWidth, navRefElem, isReady, navItems, dropdownLinks.length]);

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setDropdownVisible(false));

  return (
    <nav className={styles.nav} data-testid="navigation">
      <div ref={navRef} className={styles.horizontalNavigation}>
        {isReady && navItems.map((item) => renderNavigationItem({ ...item, forceReload }))}
      </div>

      {dropdownLinks.length > 0 && (
        <div ref={dropdownRef} className={styles.dropdown}>
          <button onClick={() => setDropdownVisible(!isDropdownVisible)} className={styles.dropdownToggleButton}>
            More <CaretDownIcon className={styles.dropdownToggleButtonIcon} />
          </button>

          {isDropdownVisible && (
            <div className={styles.dropdownWrap} onClick={() => setDropdownVisible(false)}>
              <div className={styles.dropdownLinks}>
                {navItems
                  .filter((item: NavigationItemProps) => dropdownLinks.includes(item.id))
                  .map((item) => renderNavigationItem({ ...item, forceReload }))}
              </div>
            </div>
          )}
        </div>
      )}
    </nav>
  );
};

const mapStateToProps = (state: RootState) => ({
  isLoadingSite: getSitesLoadingStatus(state),
  isAdmin: isAdminUser(state),
  site: getCurrentSite(state),
  siteUrl: getSiteUrl(state),
  settings: getSiteSettings(state),
  userSiteRoles: getUserSiteRoles(state),
});

export default connect(mapStateToProps)(Navigation);
export { Navigation as NavigationComponent };
