import React, { useState } from 'react';
import { connect } from 'react-redux';

import ListItem from '../DisplayListItem';
import DisplayRemoveConfirmModal from '../DisplayRemoveConfirmModal';
import { onRemove } from 'concepts/display-list';
import useSiteId from 'hooks/useSiteId';
import useSections from 'hooks/api/useSections';

type DisplayListProps = {
  displays: DisplayListItem[];
  onRemove: any;
  embedUrlAliasEnabled: boolean;
};

const DisplayList = ({ onRemove, displays, embedUrlAliasEnabled }: DisplayListProps) => {
  const siteId = useSiteId();
  const { sections } = useSections(siteId, { pageSize: null });
  const [removingDisplay, setRemovingDisplay] = useState<DisplayListItem | undefined>(undefined);

  return (
    <>
      {removingDisplay && (
        <DisplayRemoveConfirmModal
          onRemove={onRemove}
          removingDisplay={removingDisplay}
          setRemovingDisplay={setRemovingDisplay}
        />
      )}

      {displays.map((display) => (
        <ListItem
          sections={sections}
          onRemove={setRemovingDisplay}
          display={display}
          key={display.uuid}
          embedUrlAliasEnabled={embedUrlAliasEnabled}
        />
      ))}
    </>
  );
};

const mapDispatchToProps = { onRemove };

export default connect(null, mapDispatchToProps)(DisplayList);
