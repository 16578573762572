import WebComponent from 'utils/web-component';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { pathToReviewsNewForm } from 'services/routes';
import { Helmet } from 'react-helmet';

import Page from 'components/Page';
import Heading from 'components/Heading';

const ReviewFormsNewForm = ({ site }: { site: Site }) => {
  const history = useHistory();
  const formCategories = ["review", "general"];
  const [formName, setFormName] = useState('');
  const [formCategory, setFormCategory] = useState(formCategories[0]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    history.push(pathToReviewsNewForm(site.site_url, { name: formName, category: formCategory }));
  };

  return (
    <Page style={{ maxWidth: '56rem' }}>
      <Helmet>
        <title>Flockler {'\u203A'} Custom Forms</title>
      </Helmet>

      <Heading level="h1" type="primary">
        Create a new custom form
      </Heading>

      <div className="mx-auto max-w-2xl animate-fade-in rounded bg-slate-50 p-5 pt-3 text-center">
        <form onSubmit={handleSubmit} className="flex items-end space-x-3">
          <div className="flex-auto">
            <label htmlFor="formName" className="flex !h-9 flex-shrink-0 items-center">
              Form name
            </label>
            <input
              autoFocus
              type="text"
              name="formName"
              defaultValue={formName}
              onChange={(e) => setFormName(e.currentTarget.value)}
              className="input"
              placeholder="Type name of the form…"
            />
          </div>
          <div className="flex-initial">
            <label className="-mt-1">Choose content type</label>
            <select
              autoFocus
              name="formCategory"
              defaultValue={formCategory}
              onChange={(e) => setFormCategory(e.target.value)}
              className="input capitalize"
            >
              <option value="" disabled>
                Select form category…
              </option>

              {formCategories.map((category, idx) => (
                <option key={idx} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <WebComponent tag="fl-button" type="submit" size="medium" noclickhandling={true}>
            Continue
          </WebComponent>
        </form>
      </div>
    </Page>
  );
};

ReviewFormsNewForm.displayName = 'ReviewFormsNewForm';
export default ReviewFormsNewForm;
