import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import * as api from 'services/api';
import countBy from 'lodash/countBy';
import startsWith from 'lodash/startsWith';
import { getCurrentSite, getCurrentSiteId, getSiteUrl, getSiteSetting, getSiteId, getSiteSettings } from 'concepts/site';
import parseArticles from 'services/parse-articles';
import { AppDispatch, RootState } from 'redux/store';
import { push, replace } from 'redux-first-history';
import {
  getEmbeds,
  createEmbed,
  fetchEmbeds,
  removeEmbed,
  getEmbedsLoadingState,
  createEmbedPayload,
} from 'concepts/embed';
import {
  createSlideshow,
  fetchSlideshows,
  getSlideshows,
  getSlideshowsLoadingState,
  removeSlideshow,
} from 'concepts/slideshow';
import { fetchEmbedThemes, getEmbedThemes } from 'concepts/embed-theme';
import getEmbedCode from 'services/embed-code';
import getEmbedPreviewUrl from 'services/embed-preview-url';
import displayTypes, { CAROUSEL, EMBED_SLIDESHOW, GRID, SLIDESHOW, WALL } from 'constants/display-types';
import { pathToNewDisplay, pathToNewEmbed, pathToNewEmbedScreen, pathToNewSlideshow } from 'services/routes';
import {
  createEmbedScreen,
  createEmbedScreenPayload,
  fetchEmbedScreens,
  getEmbedScreens,
  getEmbedScreensLoadingState,
  removeEmbedScreen,
} from './embed-screen';
import { createAppAsyncThunk } from 'redux/thunk';
import { createSelector, createSlice } from '@reduxjs/toolkit';

export type DisplayType = typeof CAROUSEL | typeof WALL | typeof GRID | typeof SLIDESHOW | typeof EMBED_SLIDESHOW;

// # Action types

const FETCH_PREVIEW_ARTICLES = 'displayList/FETCH_PREVIEW_ARTICLES';
const INIT_DISPLAYS = 'displayList/INIT_DISPLAYS';
const INIT_DISPLAY_VIEW = 'displayList/INIT_DISPLAY_VIEW';
const ON_CREATE = 'displayList/ON_CREATE';

// # Utils

export const getStyleName = (embed: Embed) => {
  const style = embed?.configuration?.style;
  return get(displayTypes, [style, 'name']);
};

export const getEmbedType = (embed: Embed) => {
  const style = embed?.configuration?.style;
  const type = get(displayTypes, [style, 'type']);
  return type === 'slideshow' ? EMBED_SLIDESHOW : type;
};

const getDefaultStyle = (defaultStyles: any, type: string) =>
  (defaultStyles || []).find((style: string) => startsWith(style, type));

const getDefaultStyleByDisplayType = (
  displayType: DisplayType,
  isEmbedScreen: boolean,
  state: RootState
): EmbedStyleType => {
  switch (displayType) {
    case CAROUSEL:
      return getDefaultCarouselStyle(state);
    case EMBED_SLIDESHOW:
      return 'slideshow';
    case WALL: {
      const wallVersion = getDefaultWallStyle(state);

      return isEmbedScreen ? 'wall_v2' : wallVersion === 'wall' ? 'wall_v1' : wallVersion || 'wall_v1';
    }
    default:
      return 'grid';
  }
};

// # Item types

  type CreateEmbedListItemValues = {
    siteUuid: string;
    siteUrl: string;
    embedThemes: EmbedTheme[];
    embedUrlAliasEnabled: boolean;
  }

export const createEmbedListItem = ({ siteUuid, siteUrl, embedThemes, embedUrlAliasEnabled }: CreateEmbedListItemValues) =>
  (embed: Embed): EmbedListItem => ({
    ...embed,
    embedCode: getEmbedCode(embed, {urlAliasEnabled: embedUrlAliasEnabled}),
    embedTheme: embedThemes.find((theme) => theme.id === embed.embed_theme_id),
    itemType: 'embed',
    previewUrl: getEmbedPreviewUrl({ siteUuid, siteUrl, embedUuid: embed.uuid, embedUrlAlias: embed.url_alias, urlAliasEnabled: embedUrlAliasEnabled, style: embed.configuration.style }),
    embedUrlAliasEnabled,
    sectionIds: embed?.configuration?.section_ids,
    styleName: getStyleName(embed),
    type: getEmbedType(embed),
});

export const createSlideshowListItem = (slideshow: Slideshow): SlideshowListItem => ({
  ...slideshow,
  previewUrl: slideshow.shortcut_url,
  sectionIds: slideshow.section_id ? [slideshow.section_id] : undefined,
  styleName: displayTypes.slideshow.name,
  type: displayTypes.slideshow.type,
  itemType: 'slideshow',
});

export const createEmbedScreenListItem = (embedScreen: EmbedScreen): EmbedScreenListItem => ({
  ...embedScreen,
  previewUrl: embedScreen.shortcut_url,
  sectionIds: embedScreen.embed?.configuration?.section_ids,
  styleName: getStyleName(embedScreen.embed),
  type: getEmbedType(embedScreen.embed),
  itemType: 'screen',
});

// # Selectors

export const getDisplayListLoadingStatus = createSelector(
  getEmbedsLoadingState,
  getSlideshowsLoadingState,
  getEmbedScreensLoadingState,
  (...loadingList) => loadingList.some((loading) => loading)
);

export const getDisplayList = createSelector(
  getEmbeds,
  getSlideshows,
  getEmbedScreens,
  getEmbedThemes,
  getCurrentSite,
  getSiteSettings,
  (embeds, slideshows, embedScreens, embedThemes, site, settings) => {
    const embedUrlAliasEnabled = !!settings?.embed_url_alias_enabled;
    const embedsList = embeds.map(createEmbedListItem({ siteUuid: site?.uuid, siteUrl: site?.site_url, embedThemes, embedUrlAliasEnabled }));
    const slideshowList = slideshows.map(createSlideshowListItem);
    const embedScreenList = embedScreens.map(createEmbedScreenListItem);

    return sortBy([...embedsList, ...slideshowList, ...embedScreenList], 'created_at').reverse();
  }
);

const getEmbedsCountsByType = createSelector(getEmbeds, getEmbedScreens, (embeds, embedScreens) => {
  return countBy([...embeds, ...embedScreens.map((screen) => screen.embed)], getEmbedType);
});

const getPreviewArticles = (state: RootState) => state.displayList.previewArticles;
export const getPreviewArticlesLoading = (state: RootState) => state.displayList.isLoadingPreviewArticles;
export const getInitializedFlag = (state: RootState) => state.displayList.isInitialized;

export const getPreviewPosts = createSelector(getPreviewArticles, (articles) => parseArticles(articles));

export const getDefaultCarouselStyle = createSelector(getSiteSetting('default_embed_styles'), (defaultEmbedStyles) =>
  getDefaultStyle(defaultEmbedStyles, 'carousel')
);

export const getDefaultWallStyle = createSelector(getSiteSetting('default_embed_styles'), (defaultEmbedStyles) =>
  getDefaultStyle(defaultEmbedStyles, 'wall')
);

// # Action creators

export const fetchPreviewArticles = createAppAsyncThunk(FETCH_PREVIEW_ARTICLES, async (_, thunkApi) => {
  const siteId = getCurrentSiteId(thunkApi.getState());

  if (!siteId) {
    return Promise.reject();
  }

  return (await api.fetchSiteArticles(siteId, { state: 'published', count: 12 })).data.articles;
});

const initDisplays = createAppAsyncThunk(INIT_DISPLAYS, async (_, thunkApi) => {
  const isInitialized = getInitializedFlag(thunkApi.getState());

  if (isInitialized) {
    return Promise.resolve(true);
  }

  await Promise.all([
    thunkApi.dispatch(fetchEmbeds()),
    thunkApi.dispatch(fetchEmbedScreens()),
    thunkApi.dispatch(fetchEmbedThemes()),
    thunkApi.dispatch(fetchSlideshows()),
    thunkApi.dispatch(fetchPreviewArticles()),
  ]);

  thunkApi.dispatch(setInitialized()); // one flag for whole app, need to reset if site can be changed
});

export const initNewDisplayView = initDisplays;

export const initDisplayView = createAppAsyncThunk(INIT_DISPLAY_VIEW, async (_, thunkApi) => {
  await thunkApi.dispatch(initDisplays());

  const displays = getDisplayList(thunkApi.getState());

  // Redirect to new-display view if site has no displays yet
  if (displays.length === 0) {
    const siteUrl = getSiteUrl(thunkApi.getState());
    thunkApi.dispatch(replace(pathToNewDisplay(siteUrl)));
  }

  return Promise.resolve(true);
});

interface OnCreateOptions {
  displayType: DisplayType;
  sections: Section[];
  selectedSections?: number[];
  isEmbedScreen?: boolean;
}

export const onCreate = createAppAsyncThunk(
  ON_CREATE,
  async ({ displayType, sections, selectedSections, isEmbedScreen = false }: OnCreateOptions, thunkApi) => {
    const state = thunkApi.getState();

    const siteUrl = getSiteUrl(state);
    const siteId = getSiteId(state);

    if (displayType === SLIDESHOW) {
      const slideshows = getSlideshows(thunkApi.getState());
      const slideshowPayload = {
        name: `Slideshow #${slideshows.length + 1}`,
        section_id: selectedSections?.[0],
      };

      const payload = await thunkApi.dispatch(createSlideshow(slideshowPayload)).unwrap();

      if (payload) {
        thunkApi.dispatch(push(pathToNewSlideshow(siteUrl, payload.uuid)));
      }

      return payload;
    }

    const defaultStyle = getDefaultStyleByDisplayType(displayType, isEmbedScreen, state);
    const styleName = get(displayTypes, [defaultStyle, 'name']);
    const embedCountsByType = getEmbedsCountsByType(state);
    const embedCountForType = get(embedCountsByType, displayType, 0);

    // Use site section as default if site has only 1, otherwise use parameter
    const section_ids = sections?.length === 1 && !selectedSections ? [sections[0].id] : selectedSections;

    const embed = {
      name: `${styleName} #${embedCountForType + 1}`,
      configuration: {
        style: defaultStyle,
        section_ids,
      },
    };

    const embedPayload = createEmbedPayload(embed, siteId);

    if (isEmbedScreen) {
      const embedScreenPayload = createEmbedScreenPayload(embedPayload);

      const payload = await thunkApi
        .dispatch(createEmbedScreen({ name: embedPayload.name, embed: embedScreenPayload }))
        .unwrap();

      if (payload) {
        thunkApi.dispatch(push(pathToNewEmbedScreen(siteUrl, payload.uuid)));
      }

      return payload;
    }

    const payload = await thunkApi.dispatch(createEmbed(embedPayload)).unwrap();

    if (payload) {
      thunkApi.dispatch(push(pathToNewEmbed(siteUrl, payload.uuid)));
    }

    return payload;
  }
);

export const onRemove = (display: DisplayListItem) => (dispatch: AppDispatch) => {
  switch (display.itemType) {
    case 'embed':
      return dispatch(removeEmbed(display.uuid));
    case 'slideshow':
      return dispatch(removeSlideshow(display.uuid));
    case 'screen':
      return dispatch(removeEmbedScreen(display.uuid));
  }
};

// # Initial State

interface DisplayListState {
  previewArticles: Article[];
  isLoadingPreviewArticles: boolean;
  isInitialized: boolean;
}

export const initialState: DisplayListState = {
  previewArticles: [],
  isLoadingPreviewArticles: false,
  isInitialized: false,
};

// # Slice

const displayListSlice = createSlice({
  name: 'displayList',
  initialState,
  reducers: {
    setInitialized: (state) => {
      state.isInitialized = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPreviewArticles.pending, (state) => {
      state.isLoadingPreviewArticles = true;
    });
    builder.addCase(fetchPreviewArticles.fulfilled, (state, action) => {
      state.previewArticles = action.payload ?? [];
      state.isLoadingPreviewArticles = false;
    });
    builder.addCase(fetchPreviewArticles.rejected, (state) => {
      state.isLoadingPreviewArticles = false;
    });
  },
});

const { actions, reducer } = displayListSlice;
const { setInitialized } = actions;

export { setInitialized };

export default reducer;
