import Analytics, { AnalyticsInstance } from 'analytics';
import postHog from '@metro-fs/analytics-plugin-posthog';
import appConfig from 'config';

const initPostHogAnalytics = () => {
  return Analytics({
    app: 'flockler',
    plugins: [
      postHog({
        token: appConfig.postHogApiKey,
        enabled: true,
        options: {
          api_host: appConfig.postHogHost,
          debug: process.env.REACT_APP_ENV !== 'production',
          persistence: 'memory',
          disable_session_recording: true,
        },
      }),
    ],
  });
};

let _postHogInstance: AnalyticsInstance | null = null;
const getPostHogInstance = () => {
  if (!_postHogInstance) {
    _postHogInstance = initPostHogAnalytics();
  }

  return _postHogInstance;
};

export const postHogIdentifyUser = async (userId: string | number, siteId: string | number) => {
  const tracker = getPostHogInstance();
  await tracker.identify(`${userId}`);
  const groupProperties = { $group_type: 'site', $group_key: `${siteId}` };
  await tracker.track('$groupidentify', groupProperties);
};

export const postHogTrackEvent = (
  event: string,
  payload?: Record<string, string | number>,
  options?: Record<string, unknown>
): Promise<any> => {
  const tracker = getPostHogInstance();
  return tracker.track(event, payload, options);
};
