const getEmbedCode = (
  embed: Partial<Embed> | Partial<EmbedListItem>,
  { urlAliasEnabled }: { urlAliasEnabled: boolean }
) => {
  const attributes = urlAliasEnabled ? `data-flockler-embed="${embed.url_alias}"` : `id="flockler-embed-${embed.uuid}"`;
  const scriptSrc = urlAliasEnabled ? embed.aliased_url : embed.url || embed.shortcut_url;

  return `<div ${attributes}></div>\n<script src="${scriptSrc}" async></script>`;
};

export default getEmbedCode;
