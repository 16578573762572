import { AvailablePlans, Plan, PlanType } from 'concepts/plan';
import usePlanType from '../hooks/usePlanType';
import { useHistory } from 'react-router-dom';
import useSiteUrl from 'hooks/useSiteUrl';
import { pathToSettingsSubscriptionDetails } from 'services/routes';
import SectionTitle from './SectionTitle';
import Section from './Section';
import BillingCycleOption, { BillingCycleOptionProps } from './BillingCycleOption';
import { useCallback, useMemo } from 'react';

interface BillingCycleFieldProps {
  showCycles: boolean;
  availablePlans: AvailablePlans;
}

const billingTypes = [
  {
    value: 'monthly',
    title: 'Bill monthly',
  },
  {
    value: 'yearly',
    title: 'Bill yearly',
    savePercentage: 15,
  },
];

type BillingCycle = Omit<BillingCycleOptionProps, 'onClick'>;

const BillingCycleField = ({ showCycles, availablePlans }: BillingCycleFieldProps) => {
  const { plan, selectedPlan, isYearlyPlan, paymentMethod } = usePlanType();
  const siteUrl = useSiteUrl();
  const history = useHistory();

  const handleBillingCycleChange = useCallback(
    (plan: PlanType) => {
      history.replace(pathToSettingsSubscriptionDetails(siteUrl, plan, paymentMethod));
    },
    [history, siteUrl, paymentMethod]
  );

  const billingCycles = useMemo(
    () =>
      billingTypes.map((billingType): BillingCycle => {
        const isYearlySelected = billingType.value === 'yearly' && isYearlyPlan;
        const isMonthlySelected = billingType.value === 'monthly' && !isYearlyPlan;
        const selected = isYearlySelected || isMonthlySelected;

        const currency = selectedPlan?.currency || 'EUR';
        const planOption = `${plan.replace('_yearly', '')}${
          billingType.value === 'yearly' ? '_yearly' : ''
        }` as PlanType;

        const monthlyPrice =
          billingType.value === 'yearly'
            ? getYearlyPlan(planOption, availablePlans)?.price_per_month
            : getMonthlyPlan(planOption, availablePlans)?.price_per_month;

        return {
          ...billingType,
          savePercentage: billingType.savePercentage,
          selected,
          currency,
          plan: planOption,
          monthlyPrice,
        };
      }),
    [availablePlans, isYearlyPlan, plan, selectedPlan]
  );

  if (!showCycles) return null;

  return (
    <Section>
      <SectionTitle orderNumber={1}>Plan options</SectionTitle>

      <div className="grid auto-rows-auto grid-cols-1 gap-2 md:grid-cols-2">
        {billingCycles.map((billingCycle) => (
          <BillingCycleOption key={billingCycle.plan} {...billingCycle} onClick={handleBillingCycleChange} />
        ))}
      </div>
    </Section>
  );
};

const getMonthlyPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  return plans.monthly.find((plan: Plan) => plan.id === planId);
};

const getYearlyPlan = (planId: PlanType, plans: AvailablePlans) => {
  if (!plans) {
    return null;
  }

  return plans.yearly.find((plan: Plan) => plan.id === planId);
};

export default BillingCycleField;
