import { useState } from 'react';
import { PATHS, apiClient } from 'services/api';
import useSWR from 'swr';
import useMetricParams from './useMetricParams';
import { DateRange } from '../types';

const useMetrics = (siteId: SiteId, dateRange: DateRange, { embedUuid }: { embedUuid?: string } = {}) => {
  const [isLoadingSlow, setIsLoadingSlow] = useState(false);

  const { metricParams } = useMetricParams(dateRange);

  const { data, error } = useSWR(
    embedUuid ? PATHS.METRICS_EMBED(siteId, embedUuid, metricParams) : PATHS.METRICS_SITES(siteId, metricParams),
    metricsGetter,
    {
      loadingTimeout: 5000,
      onLoadingSlow: () => setIsLoadingSlow(true),
      onSuccess: () => setIsLoadingSlow(false),
      onError: () => setIsLoadingSlow(false),
    }
  );

  return {
    data,
    isLoading: !error && !data,
    isLoadingSlow,
  };
};

const metricsGetter = async (url: string) => {
  const data = await apiClient.get<MetricsSitesResponse | MetricsEmbedsResponse>(url, { timeout: 90000 });

  if (Object.hasOwn(data, 'requests')) {
    return Array.isArray((data as MetricsSitesResponse).requests)
      ? ((data as any).requests as RequestsMetric[])
      : (data as MetricsSitesResponse).requests?.embed ?? [];
  }

  return (data as MetricsEmbedsResponse).embeds.map((embed) => embed.requests);
};

export default useMetrics;
