import Icon from 'components/Icon';

const ReviewFormFileField = ({
 label,
 required,
 isFocused,
 userIsFocusingField,
 maxFileSize,
 instruction,
 iconType,
}: {
  label: React.ReactNode;
  required?: boolean;
  isFocused: boolean;
  userIsFocusingField: boolean;
  maxFileSize: string;
  instruction: string;
  iconType: 'upload-image' | 'upload';
}) => {
  return (
    <div
      className={`space-y-1 rounded-md border px-3 pt-2 pb-3 transition-opacity duration-500 ${
        userIsFocusingField
          ? isFocused
            ? 'border-brand opacity-100'
            : 'border-transparent opacity-30'
          : 'border-transparent'
      }`}
    >
      <div className="flex items-center justify-between">
        <span className="text-sm font-semibold">
          {label}
          {required && <span className="ml-1 opacity-50">*</span>}
        </span>
        <span className="select-none text-xs opacity-60">{`Max. ${maxFileSize}`}</span>
      </div>
      <div className="flex h-20 select-none items-center justify-center space-x-2 rounded-md border border-dashed border-slate-300 text-sm font-semibold text-slate-400">
        <Icon type={iconType} className="h-5 w-5" />
        <span>{instruction}</span>
      </div>
    </div>
  );
};

ReviewFormFileField.displayName = 'ReviewFormFileField';
export default ReviewFormFileField;
