import config from 'config';

export const DEFAULT_IFRAME_HEIGHT = 800;
export const DEFAULT_CAROUSEL_IFRAME_HEIGHT = 440;
export const CARDS_CAROUSEL_IFRAME_HEIGHT = 540;

export const getShortEmbedIframeCode = (siteUuid: string, embedUuid: string, embedUrlAliasEnabled: boolean, siteUrl: string, embedUrlAlias: string, height?: number) => {
  const slug = embedUrlAliasEnabled ? `${siteUrl}/${embedUrlAlias}` : `${siteUuid}/${embedUuid}`;

  return `<iframe src="${config.flocklerPluginsUrl}/embed/iframe/${slug}"`
    + `\nstyle="display: block; border: none; width: 100%;"${height ? ` height="${height}"` : ''} `
    + `${embedUrlAliasEnabled ? `data-flockler-embed-iframe="${embedUrlAlias}"` : `id="flockler-embed-iframe-${embedUuid}"`}>`
    + `</iframe>`;
}

export const getLongEmbedIframeCode = (siteUuid: string, embedUuid: string, embedUrlAliasEnabled: boolean, siteUrl: string, embedUrlAlias: string, height?: number, ) => {
  const slug = embedUrlAliasEnabled ? `${siteUrl}/${embedUrlAlias}` : `${siteUuid}/${embedUuid}`;

  return `<iframe src="${config.flocklerPluginsUrl}/embed/iframe/${slug}" `
    + `${embedUrlAliasEnabled ? `data-flockler-embed-iframe="${embedUrlAlias}"` : `id="flockler-embed-iframe-${embedUuid}"`} `
    + `${height ? `height="${height}" ` : ''}\n`
    + `style="display: block; border: none; width: 100%;" allowfullscreen></iframe>`;
}
