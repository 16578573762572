import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useState } from 'react';
import { toSortable } from 'services/sortable';
import { SectionFormValues } from '../SectionForm';
import { SectionsListItem } from '../SectionsListItem';
import { SectionsListItemDeleteModal } from '../SectionsListItemDeleteModal';
import { SectionsListItemEditModal } from '../SectionsListItemEditModal';

interface ActiveSectionState {
  action: 'edit' | 'delete' | 'order';
  section: Section;
}

interface SectionsListProps {
  sectionUrl: string;
  entityName: string;
  sections: OptionalPostStats<Section>[];
  onItemDelete?: (section: Section) => void;
  onItemUpdate?: (sectionId: SectionId, payload: Partial<Section>) => void;
  onItemReorder?: (sectionId: SectionId, orderNumber: number) => void;
}

export const SectionsList = ({
  sections,
  entityName,
  sectionUrl,
  onItemUpdate,
  onItemDelete,
  onItemReorder,
}: SectionsListProps) => {
  const [activeSection, setActiveSection] = useState<ActiveSectionState | null>();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const items = sections.map(toSortable);

  const dismissAction = () => {
    setActiveSection(null);
  };

  const saveAction = (payload: SectionFormValues) => {
    if (activeSection && onItemUpdate) {
      onItemUpdate(activeSection.section.id, payload);
    }

    dismissAction();
  };

  const deleteAction = () => {
    if (activeSection && onItemDelete) {
      onItemDelete(activeSection.section);
    }

    dismissAction();
  };

  const markItemToEdit = (section: Section) => {
    setActiveSection({ action: 'edit', section });
  };

  const markItemToDelete = (section: Section) => {
    setActiveSection({ action: 'delete', section });
  };

  const handleDragStart = (e: DragStartEvent) => {
    const section = items.find((item) => item.id === e.active.id);

    if (section) {
      setActiveSection({ action: 'order', section: section.entity });
    }
  };

  const handleDragEnd = (e: DragEndEvent) => {
    if (activeSection && e.over && e.over.id !== e.active.id) {
      const overSection = items.find((item) => item.id === e.over?.id);

      if (overSection && onItemReorder) {
        onItemReorder(activeSection.section.id, overSection.entity.order_number);
      }
    }

    dismissAction();
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      modifiers={[restrictToVerticalAxis]}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        <div>
          <ol data-testid="sections-list">
            {items.map((item) => (
              <SectionsListItem
                key={item.id}
                item={item}
                entityName={entityName}
                url={sectionUrl}
                onEdit={onItemUpdate ? markItemToEdit : undefined}
                onDelete={onItemDelete ? markItemToDelete : undefined}
                sortable={!!onItemReorder}
              />
            ))}
          </ol>

          {activeSection?.action === 'delete' && (
            <SectionsListItemDeleteModal
              entityName={entityName}
              section={activeSection.section}
              deleteAction={deleteAction}
              dismissAction={dismissAction}
            />
          )}

          {activeSection?.action === 'edit' && (
            <SectionsListItemEditModal
              entityName={entityName}
              section={activeSection.section}
              saveAction={saveAction}
              dismissAction={dismissAction}
            />
          )}
        </div>

        <DragOverlay adjustScale modifiers={[restrictToParentElement]}>
          {activeSection?.action === 'order' && (
            <SectionsListItem
              item={toSortable(activeSection.section)}
              entityName={entityName}
              url={sectionUrl}
              onEdit={onItemUpdate ? markItemToEdit : undefined}
              onDelete={onItemDelete ? markItemToDelete : undefined}
              sortable={!!onItemReorder}
              className="animate-elevate rounded bg-slate-50 outline outline-1 outline-slate-300"
            />
          )}
        </DragOverlay>
      </SortableContext>
    </DndContext>
  );
};
