import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { RootState } from 'redux/store';
import { getSiteUuid, getSiteUrl, getSiteSettings } from 'concepts/site';
import getEmbedPreviewUrl from 'services/embed-preview-url';
import getEmbedEditPreviewUrl from 'services/embed-edit-preview-url';
import EmbedPreviewBrowser from '../EmbedPreviewBrowser';
import HelpScoutLink from 'components/HelpScoutLink';
import Icon from 'components/Icon';
import SaveButton from 'components/SaveButton';
import useScrollWatcher from 'hooks/useScrollWatcher';
import copyToClipboard from 'services/copy-to-clipboard';

import styles from './EmbedPreview.module.scss';
import CopyButton from 'components/CopyButton';
import useCarouselStyle from 'pages/display/hooks/useCarouselStyle';

type EmbedPreviewProps = {
  editValues: EmbedEditValues;
  editVersion: number;
  embed: Embed;
  embedCodeRef: any;
  embedUpdateError: any;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
  saveEmbed: () => void;
  selectedCodeType: EmbedCodeType;
  siteUuid: string;
  siteUrl: string;
  siteSettings: SiteSettings;
  setIsframeCopyModalOpen: (isOpen: boolean) => void;
  embedUrlAliasEnabled: boolean;
};

const ChatWithUsMessage = () => (
  <div className={styles.chatWithUsMessage}>
    Styles can be customized with CSS below. <HelpScoutLink>Chat with us</HelpScoutLink> if you need any help.
  </div>
);

function EmbedPreview({
  editValues,
  editVersion,
  embed,
  embedCodeRef,
  embedUpdateError,
  hasUnsavedChanges,
  isSaving,
  saveEmbed,
  selectedCodeType,
  siteUuid,
  siteUrl,
  siteSettings,
  setIsframeCopyModalOpen,
}: EmbedPreviewProps) {
  const [isCopiedMessageVisible, setCopiedMessageVisible] = useState(false);
  const previewEl = React.createRef<HTMLDivElement>();
  const isPreviewVisible = useScrollWatcher(previewEl, 300);
  const carouselStyle = useCarouselStyle(editValues);
  const isCarousel = embed?.configuration?.style === 'carousel_v1' || embed?.configuration?.style === 'carousel_v2';
  const isPipMode = !isPreviewVisible;

  const editPreviewUrl = getEmbedEditPreviewUrl({
    configuration: editValues,
    embedUuid: embed.uuid,
    siteUuid,
    editVersion,
  });

  const fullPreviewUrl = getEmbedPreviewUrl({
    siteUuid,
    siteUrl,
    embedUuid: embed.uuid,
    embedUrlAlias: embed.url_alias,
    urlAliasEnabled: siteSettings?.embed_url_alias_enabled,
    style: embed.configuration.style,
  });

  return (
    <div ref={previewEl} className={classnames(styles.preview, { [styles.pipMode]: isPipMode })}>
      <div
        className={classnames(styles.previewArea, { [styles.previewAreaCarousel]: isCarousel })}
        data-height={carouselStyle?.height}
      >
        <EmbedPreviewBrowser
          url={editPreviewUrl}
          className={styles.previewBrowser}
          isCarousel={isCarousel}
          isSlideshow={embed?.configuration?.style === 'slideshow'}
          isPipMode={isPipMode}
          backgroundColor={editValues?.theme_css_variables?.['--fl-background']}
          embedConfig={editValues}
        />

        <div className={styles.previewFooter}>
          <div className={styles.previewActions}>
            <SaveButton
              className={styles.previewSaveButton}
              type="button"
              size="small"
              isSaving={isSaving}
              hasError={!!embedUpdateError}
              action={saveEmbed}
              style="margin-right: .5rem; margin-top: .3rem; min-width: 7.5rem;"
              variant="success"
            >
              {isSaving ? 'Saving' : 'Save changes'}
            </SaveButton>

            {selectedCodeType === 'default' ? (
              <CopyButton
                isCopiedMessageVisible={isCopiedMessageVisible}
                size="small"
                style="min-width: 7.5rem; margin-top: .3rem; "
                onClick={() => {
                  copyToClipboard(embedCodeRef, setCopiedMessageVisible);
                }}
              >
                Copy the Embed code
              </CopyButton>
            ) : (
              <CopyButton
                isCopiedMessageVisible={false}
                size="small"
                style="min-width: 7.5rem;"
                onClick={() => {
                  setIsframeCopyModalOpen(true);
                }}
              >
                Copy the iframe code
              </CopyButton>
            )}
          </div>

          {hasUnsavedChanges && <span className={styles.unsavedChanges}>Unsaved changes</span>}
          <a
            href={fullPreviewUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames(styles.previewLink, { [styles.disabled]: hasUnsavedChanges })}
          >
            Open full preview <Icon className={styles.previewLinkIcon} type="external-link" />
          </a>

          <ChatWithUsMessage />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => ({
  siteUuid: getSiteUuid(state),
  siteUrl: getSiteUrl(state),
  siteSettings: getSiteSettings(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EmbedPreview);
